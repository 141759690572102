<script setup lang="ts">
import { SUPPORTED_FILE_EXTENSIONS, SUPPORTED_FILE_EXTENSIONS_HELPER } from '@/backend/types'
import { toast } from '@/shared/toast'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { ref, watch } from 'vue'
import FileCellEmpty from './FileCellEmpty.vue'
import FileCellPopulated from './FileCellPopulated.vue'
import { useFieldUpload } from './useFieldUpload'
import { useProject, type Field, type Property } from './useProject'
import { useSupportedMimeTypes } from './useSupportedMimeTypes'

const props = defineProps<{
  field: Field<'file' | 'pdf'>
  property: Property<'file' | 'pdf'>
  workspaceId: string
  projectId: string
  propertyId: string
  filename: string | null
  isFocused: boolean
  isSelected: boolean
  pdfProjectId?: string | null
  url: string | null
  hasSelectedRange: boolean
}>()

defineEmits<{
  (e: 'uploaded', url: string): void
  (e: 'cleared' | 'blur'): void
}>()

const supportedFileExtensions = [...SUPPORTED_FILE_EXTENSIONS]
const supportedMimeTypes = useSupportedMimeTypes()

const projectStore = useProject()
const { captureAnalyticsEvent } = useAnalytics()
const uploadStore = useFieldUpload()

const readyUpload = async (fileListOrFile: File[]) => {
  const files = Array.from(fileListOrFile)
  const unsupportedFiles = files.filter((f) => {
    // Some files have no type, so we fallback to the extension
    // https://developer.mozilla.org/en-US/docs/Web/API/Blob/type
    return f.type === ''
      ? !(f.name.split('.').pop() ?? '' in SUPPORTED_FILE_EXTENSIONS_HELPER)
      : !supportedMimeTypes.value.includes(f.type)
  })
  if (unsupportedFiles.length > 0) {
    toast.warning(
      [
        'Some of the files you tried to upload are not supported, so the upload was skipped.',
        'We only support the following file extensions:',
        supportedFileExtensions.join(', ').concat('.'),
      ].join(' '),
    )
    return
  }

  const [file, ...moreFiles] = files

  if (file) {
    uploadStore.addUpload({
      workspaceId: props.workspaceId,
      projectId: props.projectId,
      entityId: props.field.entityId,
      propertyId: props.propertyId,
      file,
    })
  }

  if (moreFiles.length === 0) {
    return
  }

  const currentIndex = projectStore.activeView?.entityIdToIndex?.get(props.field.entityId) ?? -1
  const remainingEntities = projectStore.activeView?.entities?.slice(currentIndex + 1) ?? []

  let foundCount = 0
  const pendingUpdates: {
    workspaceId: string
    projectId: string
    entityId: string
    propertyId: string
    file: File
  }[] = []
  for (let i = 0; foundCount < moreFiles.length && i <= remainingEntities.length; i++) {
    const entity = remainingEntities[i]
    if (entity && !entity.fields.get(props.field.propertyId)?.manualValue) {
      pendingUpdates.push({
        workspaceId: props.workspaceId,
        projectId: props.projectId,
        entityId: entity.id,
        propertyId: props.propertyId,
        file: moreFiles[foundCount],
      })
      foundCount++
    }
  }

  pendingUpdates.forEach((u) => {
    uploadStore.addUpload({
      workspaceId: u.workspaceId,
      projectId: u.projectId,
      entityId: u.entityId,
      propertyId: u.propertyId,
      file: u.file,
    })

    captureAnalyticsEvent(ANALYTICS_EVENT.FILE_UPLOAD_PENDING, {
      workspaceId: u.workspaceId,
      projectId: u.projectId,
      entityId: u.entityId,
      propertyId: u.propertyId,
    })
  })

  const pendingCreates = moreFiles.slice(pendingUpdates.length)
  if (pendingCreates.length > 0) {
    uploadStore.addPendingUploads({
      workspaceId: props.workspaceId,
      projectId: props.projectId,
      propertyId: props.propertyId,
      files: pendingCreates,
    })
  }
}

const dialogFiles = ref<File[]>([])
watch(dialogFiles, () => readyUpload(Array.from(dialogFiles.value || [])))
</script>

<template>
  <FileCellPopulated
    v-if="url && filename"
    :filename="filename"
    :url="url"
    :is-focused="isFocused"
    :is-selected="isSelected"
    :pdf-project-id="pdfProjectId"
    :entity-id="field.entityId"
    :project-id="projectId"
    :workspace-id="workspaceId"
    :has-selected-range="hasSelectedRange"
    v-bind="$attrs"
    @replace="dialogFiles = [$event]"
    @blur="$emit('blur')"
  />
  <FileCellEmpty
    v-else
    v-bind="{ ...props, ...$attrs }"
    :filename="filename"
    @uploaded="$emit('uploaded', $event)"
    @cleared="$emit('cleared')"
    @upload:files="dialogFiles = $event"
    @blur="$emit('blur')"
  />
</template>
