<script setup lang="ts">
import { computed } from 'vue'
import CheckBox from '@/uiKit/CheckBox.vue'
import { storeToRefs } from 'pinia'
import { useProject, type Entity } from './useProject'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ProjectTableActionBarSetValues from './ProjectTableActionBarSetValues.vue'
import { FeatureFlag, useFeatureFlags } from '../App/useFeatureFlags'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import { onKeyStroke } from '@vueuse/core'
import { isSelectProperty } from '@/shared/utils/typeGuards'

const emit = defineEmits<{
  (e: 'delete'): void
  (e: 'setValue', propertyId: string, value: Partial<Record<string, string[] | null>>): void
}>()
const onSetValue = (propertyId: string, value: Partial<Record<string, string[] | null>>) =>
  emit('setValue', propertyId, value)

const projectStore = useProject()

const { selectedEntityIds, activeView, allEntitiesSelected } = storeToRefs(projectStore)

const isPartialSelection = computed(
  () =>
    selectedEntityIds.value.size > 0 &&
    activeView.value &&
    activeView.value.entities &&
    selectedEntityIds.value.size < activeView.value.entities.length,
)

const canBulkEditEntities = useFeatureFlags(FeatureFlag.WORK_ASSIGNENT)
const permissionStore = usePermissionsStore()

onKeyStroke(['Backspace', 'Delete'], (e) => {
  const el = e.target
  if (el instanceof HTMLElement) {
    const isTypingIntoTextField =
      el.tagName === 'INPUT' || el.tagName === 'TEXTAREA' || el.isContentEditable
    const hasFocusedGridCell = el.closest('[role=gridcell]')
    if (isTypingIntoTextField || hasFocusedGridCell) {
      return
    }
  }

  emit('delete')
})

const selectedEntities = computed<Entity[]>(() =>
  Array.from(selectedEntityIds.value).reduce<Entity[]>((acc, id) => {
    const entity = activeView.value?.entities?.find((entity) => entity?.id === id)
    if (entity) {
      return [...acc, entity]
    }
    return acc
  }, []),
)
</script>

<template>
  <div
    class="inline-flex items-center overflow-hidden rounded-xl bg-surface-tertiary shadow-sm outline outline-1 outline-border-subtle"
  >
    <div class="rounded-lg pl-3 pr-4 pt-px text-sm-12px-default">
      {{ selectedEntityIds.size }} Selected
    </div>
    <div
      class="flex gap-1.5 rounded-xl bg-surface-primary p-1 outline outline-1 outline-border-subtle"
    >
      <CheckBox
        label="Select All"
        class="px-2 py-1 text-sm-12px-default"
        :checked="allEntitiesSelected"
        :indeterminate="isPartialSelection"
        @change="projectStore.toggleAllEntities"
      />
      <ProjectTableActionBarSetValues
        v-if="canBulkEditEntities"
        :placement="{ allowedPlacements: ['top'] }"
        :entities="selectedEntities"
        :properties="projectStore.visibleProperties.filter(isSelectProperty)"
        :offset="{ mainAxis: 4 }"
        @set-value="onSetValue"
      >
        <template #trigger="{ isOpen, toggleOpen }">
          <DarwinButton
            size="sm"
            variant="transparent"
            :class="isOpen && '!bg-background-transparent-hovered'"
            @click="toggleOpen"
          >
            Set Value
          </DarwinButton>
        </template>
      </ProjectTableActionBarSetValues>
      <DarwinButton
        v-if="permissionStore.currentProjectPermissions.delete_entities"
        size="sm"
        variant="critical-subtle"
        data-test="action-bar-delete-button"
        @click="$emit('delete')"
      >
        <div class="flex gap-1">
          <IconSprite icon="trash" />
          Delete
        </div>
      </DarwinButton>
    </div>
  </div>
</template>
