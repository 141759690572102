<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import SelectTypeOption from './SelectTypeOption.vue'
import { useProperty } from '@/modules/Project/useProperty'
import { Color } from '@/shared/Color'
import { PropertyType } from '@/backend/types'
import SelectTypeOptionsFallback from './SelectTypeOptionsFallback.vue'

const propertyStore = useProperty()
const tempValue = ref('')
const optionsRef = ref<HTMLElement>()

export type Option = {
  value: string
  color?: string | null | undefined
  tool_fallback?: boolean | null | undefined
}

export type Item = { id: string; data: Option }

const resetValues = () => {
  tempValue.value = ''
}

const filteredOptions = computed(() => {
  const options = propertyStore.visibleOptions.filter((option) =>
    option.value.toLowerCase().includes(tempValue.value.toLowerCase()),
  )
  return options
})

const toArray = (value: string) =>
  value
    .split(',')
    .map((v) => v.trim())
    .filter((v) => v)

const suggestToCreateText = computed(() => {
  if (propertyStore.property?.type === PropertyType.user_select) return
  if (tempValue.value.replace(/,/g, '') === '') return
  if (propertyStore.visibleOptions.some((option) => option.value === tempValue.value)) return
  return `Create "${toArray(tempValue.value).join('", "')}"`
})

const createNewOption = (value: string) => {
  if (!value) return
  toArray(value).forEach((v) => {
    propertyStore.createSelectOption({
      value: v,
      color: Color[(Math.random() * Color.length) | 0],
    })
  })
  resetValues()
}

const placeholderComponent = ref()

onMounted(() => {
  resetValues()
})

const isOptionMarkedAsDefault = (option: Option) => {
  if (!propertyStore.isDirty) {
    if (!propertyStore.property) return false
    if (!('config' in propertyStore.property)) return false
    if (propertyStore.property.config && 'defaultOption' in propertyStore.property.config) {
      return propertyStore.property.config.defaultOption === option.value
    }
  }
  return propertyStore.editedConfig && propertyStore.editedConfig.defaultOption
    ? propertyStore.editedConfig.defaultOption === option.value
    : false
}
</script>

<template>
  <div>
    <SelectTypeOption
      ref="placeholderComponent"
      :property-id="propertyStore.property?.id"
      :property-type="propertyStore.editedType"
      :value="tempValue"
      class="w-full"
      :show-advanced="false"
      placeholder="Search or add options"
      aria-label="Search or add options"
      @update="tempValue = $event.value"
      @create="createNewOption($event.value)"
    />
    <div
      ref="optionsRef"
      class="flex flex-col items-start justify-start"
      v-bind="$attrs"
    >
      <SelectTypeOption
        v-for="(option, idx) in filteredOptions"
        :key="idx"
        :property-id="propertyStore.property?.id"
        :property-type="propertyStore.editedType"
        :property-config="
          propertyStore.property &&
          'config' in propertyStore.property &&
          propertyStore.property.config &&
          'defaultOption' in propertyStore.property.config
            ? propertyStore.property.config
            : undefined
        "
        :is-default="isOptionMarkedAsDefault(option)"
        :is-manual="propertyStore.editedTool === 'manual'"
        :value="option.value"
        :color="option.color ?? null"
        class="w-full"
        @update="propertyStore.updateSelectOption($event, option.value)"
        @delete="propertyStore.deleteSelectOption(option.value)"
        @mark-as-default="propertyStore.markOptionAsDefault(option.value)"
        @unmark-as-default="propertyStore.markOptionAsDefault(null)"
      />
      <ListMenuItem
        v-if="suggestToCreateText"
        :label="suggestToCreateText"
        aria-label="Create new option"
        :active="true"
        icon="plus"
        @click.prevent="createNewOption(tempValue), placeholderComponent.focus()"
      />

      <template v-if="propertyStore.property?.type !== PropertyType.user_select">
        <h4 class="p-2 text-sm-12px-default text-text-subtlest">If no match found</h4>
        <SelectTypeOptionsFallback :filtered-options="filteredOptions" />
        <hr class="!col-[1/4] mx-[-4px] my-1 border-border-subtle" />
      </template>
    </div>
  </div>
</template>
