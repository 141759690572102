<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import VerifyEmailIllustration from '@/illustrations/verify-email.svg'
import IconSprite from '@/uiKit/IconSprite.vue'

const { logout } = useAuth0()
</script>

<template>
  <div class="flex size-full flex-col items-center justify-center">
    <VerifyEmailIllustration class="mb-6 w-[283px]" />
    <h1 class="mb-1 text-md-13px-bold">Please log in with your work email</h1>
    <div class="flex min-w-[200px] flex-col gap-3">
      <DarwinButton
        variant="outline"
        size="md"
        @click="logout"
        >Try again
        <template #leading-icon>
          <IconSprite
            icon="refresh"
            class="text-icon-subtle"
          />
        </template>
      </DarwinButton>
    </div>
  </div>
</template>
