<script setup lang="ts">
import type { PropertyType } from '@/backend/types'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import PopupMenu from '@/uiKit/PopupMenu.vue'
import ToolTip from '@/uiKit/ToolTip.vue'

import { FIELD_TYPES_LABELS } from '@/modules/WorkspaceSettings/propertyConfig'
import ListMenu from '@/uiKit/ListMenu.vue'
import { computed, ref } from 'vue'
import { useProjects } from '../Projects/useProjects'
import { useWelcomeTour } from '../WelcomeTour/useWelcomeTour'
import { TYPE_ICON } from './icons'
import { useFieldTypeOptions } from './useFieldTypeOptions'
import PropertyTypeTooltip from './PropertyTypeTooltip.vue'
import PopOver from '@/uiKit/PopOver.vue'

const props = defineProps<{
  projectId: string | null
}>()

const emit = defineEmits<{
  (e: 'create-property', type: PropertyType): void
}>()

const projectsStore = useProjects()
const welcomeTour = useWelcomeTour()
const menuIsOpen = ref(false)
const project = computed(() => projectsStore.projects.find((p) => p.id === props.projectId))

const fieldTypesOptions = useFieldTypeOptions()
const filteredTypes = computed(() => {
  // If the project comes from a collection, we filter out collections as we don't want nesting of collections
  if (project.value?.parentProperty) {
    return fieldTypesOptions.value.map((t) => t.id).filter((t) => t !== 'collection')
  }
  return fieldTypesOptions.value.map((t) => t.id)
})

const onSelect = (type: PropertyType) => {
  menuIsOpen.value = false
  emit('create-property', type)
}

const onOpenChange = (isOpen: boolean) => {
  menuIsOpen.value = isOpen
}

const typeTooltipIsOpen = ref<Record<PropertyType, boolean>>({
  url: false,
  single_select: false,
  multi_select: false,
  user_select: false,
  text: false,
  file: false,
  json: false,
  pdf: false,
  collection: false,
  file_collection: false,
})
</script>

<template>
  <PopupMenu
    id="add-property"
    :auto-placement="{ allowedPlacements: ['bottom-start', 'bottom-end'] }"
    class="group relative min-w-[32px] select-none items-center"
    aria-label="Add a property"
    v-bind="$attrs"
    :open="menuIsOpen"
    :disable-focus-trap="welcomeTour.status === 'IN_PROGRESS'"
    :disable-close-on-click-outside="welcomeTour.status === 'IN_PROGRESS'"
    @change:open="onOpenChange"
  >
    <template #trigger>
      <ToolTip
        title="Add property"
        :placement="{ allowedPlacements: ['top-end'] }"
      >
        <IconSprite
          icon="plus"
          size="sm"
          class="text-icon-subtle"
          data-test="add-property"
        />
      </ToolTip>
    </template>
    <template #dropdown>
      <ListMenu
        :items="filteredTypes.map((t) => ({ id: t, data: t }))"
        class="min-w-56"
        :initial-active-item-predicate="() => true"
        data-test="add-property-menu"
      >
        <template #item="{ active, item, key, setActiveItem }">
          <ListMenuItem
            :id="`add-property-menu-item-${key}`"
            role="menuitem"
            :label="FIELD_TYPES_LABELS[item.data]"
            :icon="TYPE_ICON[item.data]"
            :active="active"
            @mouseenter="typeTooltipIsOpen[item.data] = true"
            @mouseleave="typeTooltipIsOpen[item.data] = false"
            @mousemove="setActiveItem(key)"
            @select="onSelect(item.data)"
          >
            <template #prefix>
              <PopOver
                :open="typeTooltipIsOpen[item.data]"
                :target-selector="`#add-property-menu-item-${key}`"
                :placement="{ allowedPlacements: ['left-start', 'right-start'] }"
              >
                <template #content>
                  <PropertyTypeTooltip :type="item.data" />
                </template>
              </PopOver>
            </template>
          </ListMenuItem>
        </template>
      </ListMenu>
    </template>
  </PopupMenu>
</template>
