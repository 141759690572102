<script setup lang="ts">
import ListMenu from '@/uiKit/ListMenu.vue'
import { type ModelInputItem } from './useModelInputs'
import LibraryListMenuItem from '../Library/LibraryListMenuItem.vue'
import ListMenuCheckboxItem from '@/uiKit/ListMenuCheckboxItem.vue'
import { TYPE_ICON } from './icons'
import IconButton from '@/uiKit/IconButton.vue'
import { computed, ref } from 'vue'
import { isCollectionProperty } from '@/shared/utils/typeGuards'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import type { CollectionOrFileCollection } from './ModelInputMenuFilters.vue'
import ModelInputMenuFilters from './ModelInputMenuFilters.vue'
import type { PropertyInput } from './useProject'
import { FeatureFlag, useFeatureFlags } from '../App/useFeatureFlags'
import { useSubProject } from './useSubProject'
import { isFilterableProperty, type FilterableProperty } from './Filters/types'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
/**
 * This component renders a list of items that can be selected as inputs to a model.
 */

const props = defineProps<{
  items: ModelInputItem[]
  selectedInputIds: string[]
}>()

const emit = defineEmits<{
  (e: 'toggle:property' | 'toggle:library-item', id: string): void
  (
    e: 'update:filters',
    payload: { propertyId: string; filters: PropertyInput['filters'] | null },
  ): void
  (e: 'open:library'): void
}>()

type ItemGroup = ModelInputItem['data']['group'] | 'Selected'
type Item = {
  id: string
  data: ModelInputItem['data'] & { selectedGroup: ItemGroup }
}

/**
 * In the UI, we want to group selected items separately, so update an item's group to
 * 'Selected' if it is selected.
 */
const groupedItems = computed<Item[]>(() =>
  props.items.map((item) => ({
    ...item,
    data: {
      ...item.data,
      selectedGroup: props.selectedInputIds.includes(item.data.id) ? 'Selected' : item.data.group,
    },
  })),
)

const itemIsSelected = (item: Item) => props.selectedInputIds.includes(item.id)

const onToggleItem = (id: string) => {
  const item = props.items.find((item) => item.data.id === id)
  if (!item) return

  if (item.data.group === 'Library') {
    emit('toggle:library-item', id)
  } else {
    emit('toggle:property', id)
  }
}

const filterProperty = ref<{
  property: CollectionOrFileCollection
  filters: PropertyInput['filters']
  subprojectProperties: FilterableProperty[]
} | null>(null)

/**
 * For a given property, get all the properties that are filterable
 * subproperties of it.
 */
const getSubprojectProperties = (property: CollectionOrFileCollection) =>
  subprojectStore.properties
    .filter((p) => p.parentPropertyId === property.id)
    .filter(isFilterableProperty)
    .map((p) => ({
      ...p,
      // Subproject properties are prefixed with the parent property name, so
      // remove it for display.
      name: p.name.replace(`${property.name}/`, ''),
    }))

const subprojectStore = useSubProject()
const onOpenFilterMenu = (
  property: CollectionOrFileCollection,
  filters: PropertyInput['filters'],
) => {
  filterProperty.value = {
    property,
    filters,
    subprojectProperties: getSubprojectProperties(property),
  }
}

const inputCollectionsEnabled = useFeatureFlags(FeatureFlag.INPUT_COLLECTIONS)

const onUpdateFilters = (filters: PropertyInput['filters'] | null) => {
  assertIsNotNullOrUndefined(filterProperty.value, 'No property set when updating filters')

  emit('update:filters', {
    propertyId: filterProperty.value.property.id,
    filters,
  })
}
</script>

<template>
  <ModelInputMenuFilters
    v-if="filterProperty"
    :property="filterProperty.property"
    :filters="filterProperty.filters"
    :subproject-properties="filterProperty.subprojectProperties"
    @close="filterProperty = null"
    @update="onUpdateFilters"
  />
  <ListMenu
    v-else
    role="listbox"
    class="min-w-[240px]"
    aria-label="Select input properties"
    :items="groupedItems"
    search-by-field="name"
    :group-by-predicate="(item) => item.data.selectedGroup"
    :group-order="['Selected', 'Properties', 'Library']"
    :scroll-level="'group'"
    :has-group-titles="true"
    @select="onToggleItem($event.id)"
  >
    <template #group-title="{ group }">
      <div v-if="group.key === 'Selected'" />
      <div
        v-else
        class="flex h-7 items-center justify-between px-1.5 py-0.5"
      >
        <h4 class="text-xs-11px-bold text-text-subtlest">
          {{ group.key }}
        </h4>
        <IconButton
          v-if="group.key === 'Library'"
          icon="folder"
          size="xs"
          variant="transparent"
          aria-label="Open library"
          @click="$emit('open:library')"
        />
      </div>
    </template>
    <template #item="{ key, item, active, setActiveItem }">
      <LibraryListMenuItem
        v-if="item.data.group === 'Library'"
        :item="item.data"
        :checked="itemIsSelected(item)"
        :aria-selected="itemIsSelected(item)"
        :hide-date="itemIsSelected(item)"
        default-hover-disabled
        :active="active"
        @mousemove="setActiveItem(key)"
        @select="onToggleItem(item.id)"
      />
      <ListMenuCheckboxItem
        v-else
        :label="item.data.name"
        :active="active"
        :checked="itemIsSelected(item)"
        :indeterminate="item.data.filterCount > 0"
        :aria-selected="itemIsSelected(item)"
        :icon="item.data.group === 'Properties' ? TYPE_ICON[item.data.type] : undefined"
        default-hover-disabled
        @mousemove="setActiveItem(key)"
        @select="onToggleItem(item.id)"
      >
        <template
          v-if="
            isCollectionProperty(item.data) &&
            inputCollectionsEnabled &&
            (active || item.data.filterCount > 0)
          "
          #suffix
        >
          <DarwinButton
            variant="transparent"
            size="xxs"
            @click="onOpenFilterMenu(item.data, item.data.filters)"
          >
            {{
              item.data.filterCount > 0
                ? `${item.data.filterCount} filter${item.data.filterCount > 1 ? 's' : ''}`
                : 'Filter'
            }}
          </DarwinButton>
        </template>
      </ListMenuCheckboxItem>
    </template>
  </ListMenu>
</template>
