import { PropertyType } from '@/backend/types'
import type { Field, Property } from '@/modules/Project/useProject'

export const isSelectField = (
  field: Field,
): field is Field<'single_select' | 'multi_select' | 'user_select'> =>
  field.type === PropertyType.single_select ||
  field.type === PropertyType.multi_select ||
  field.type === PropertyType.user_select

export const isSelectProperty = (
  property?: Property,
): property is Property<'single_select' | 'multi_select' | 'user_select'> =>
  !!property &&
  (property.type === PropertyType.single_select ||
    property.type === PropertyType.multi_select ||
    property.type === PropertyType.user_select)

export const isFileField = (field: Field | undefined): field is Field<'file' | 'pdf'> =>
  !!field && (field.type === PropertyType.file || field.type === PropertyType.pdf)

export const isFileProperty = (
  property: Property | undefined,
): property is Property<'file' | 'pdf'> =>
  !!property && (property.type === PropertyType.file || property.type === PropertyType.pdf)

export const isCollectionProperty = (
  property: Property | undefined,
): property is Property<'collection' | 'file_collection'> =>
  !!property &&
  (property.type === PropertyType.collection || property.type === PropertyType.file_collection)

/**
 * Returns true if and only if the input is an object with no keys.
 */
export const isEmptyObject = (input: unknown): input is Record<string, never> => {
  if (typeof input !== 'object' || input === null) {
    return false
  }

  return Object.keys(input).length === 0
}
