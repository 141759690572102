<script setup lang="ts">
import AccountDropdown from '@/modules/IdentityAndAccess/AccountDropdown.vue'
import ProjectDropdown from '@/sharedComponents/ProjectDropdown.vue'
import SidebarToggle from '@/sharedComponents/SidebarToggle.vue'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import NavigationList from '@/uiKit/NavigationList.vue'
import NavigationListItem from '@/uiKit/NavigationListItem.vue'

import { useBilling } from '@/modules/Billing/useBilling'
import type { Project } from '@/modules/Projects/useProjects'
import { useProjects } from '@/modules/Projects/useProjects'
import { useSidebar } from '@/sharedComponents/useSidebar'
import DividerLine from '@/uiKit/DividerLine.vue'
import { useScroll } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, ref, toRef } from 'vue'

import ProjectName from '@/modules/Project/ProjectName.vue'
import { useMeterColor } from '@/shared/useMeterColor'
import LoadingSkeleton from '@/sharedComponents/LoadingSkeleton.vue'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import FlowerProgressVue from '@/uiKit/FlowerProgress.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { useCsvImportModal } from '../Workspaces/CsvImportModal.vue'
import { useTemplateModal } from '../Workspaces/TemplateModal.vue'
import ObjectURLImage from './ObjectURLImage.vue'
import ProjectSidebarLimitUsage from './ProjectSidebarLimitUsage.vue'
import { useCreateProject } from './useCreateProject'
import { useLoadAllProjects } from './useLoadAllProjects'
import { useRecentProjects } from './useRecentProjects'
import { useLibraryStore } from '@/modules/Library/libraryStore'
import { useHotKey } from '@/sharedComposables/useHotKey'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'

const props = defineProps<{
  workspaceId: string
  open: boolean
}>()

const templateModal = useTemplateModal()
const csvModal = useCsvImportModal()
const projectsStore = useProjects()
const { mainProjects: projects } = storeToRefs(projectsStore)
const hasWorkspaceProjects = computed(() => projects.value.length > 0)

const billingStore = useBilling()

const { isOpen, toggle } = useSidebar(computed(() => props.open))
useHotKey('[', toggle)

const seatUsage = computed(() => billingStore.seatUsage)
const seatUsageColor = useMeterColor({
  current: computed(() => seatUsage.value?.limitUsage ?? 0),
  max: computed(() => seatUsage.value?.limitValue ?? 0),
  defaultColor: 'blue',
})
const { sidebarProjects } = useLoadAllProjects(toRef(props, 'workspaceId'))

const containerRef = ref<HTMLElement | null>(null)
const { arrivedState } = useScroll(containerRef)

const { createNewProject } = useCreateProject()

const isCreating = ref<boolean>(false)

const onCreateProject = async () => {
  if (!isOpen.value || isCreating.value) {
    return
  }

  isCreating.value = true
  await createNewProject(props.workspaceId)
  isCreating.value = false
}

const recentProjectsStore = useRecentProjects()
const onImageSrcError = (project: Project) => {
  projectsStore.setCoverImageDownloadError(project.id, true)
  recentProjectsStore.setCoverImageDownloadError(project.id, true)
}

const showUsage = computed(
  () => billingStore.activePlan?.name === 'free' || billingStore.activePlan?.name === 'pro',
)

const libraryStore = useLibraryStore()

const permissionsStore = usePermissionsStore()
const projectIsDisabled = (project: Project) =>
  project.propertyCount === 0 && permissionsStore.workspaceRole === 'worker'

/**
 * Returns true if one of the sidebar dialogs is open. Is used so that
 * we can disable the active state for the current link if a dialog
 * is open.
 */
const someDialogIsOpen = computed(
  () => libraryStore.dialogIsOpen || templateModal.isOpen.value || csvModal.isOpen.value,
)

const { captureAnalyticsEvent } = useAnalytics()
const onClick = () => {
  captureAnalyticsEvent(ANALYTICS_EVENT.CLICK_GO_PRO)
}

const NEW_PROJECT_BUTTON_ID = 'new-project-button-sidebar'
</script>

<template>
  <nav
    class="relative flex h-full flex-col border-r border-border-subtle bg-surface-secondary transition-all duration-300 ease-in-out-quint"
    :class="isOpen ? 'w-[240px]' : 'w-[0px]'"
    :aria-hidden="!isOpen"
    aria-label="Main"
  >
    <SidebarToggle
      data-test="sidebar-toggle"
      class="absolute right-2 top-2 z-1 transition duration-300 ease-in-out-quint"
      :class="isOpen ? 'translate-x-0' : 'translate-x-8'"
      :disable-tooltip="!isOpen"
    />
    <div
      class="flex h-full w-[240px] flex-col overflow-hidden transition duration-300 ease-in-out-quint"
      :class="isOpen ? 'scale-100 opacity-100' : 'scale-90 opacity-0'"
    >
      <div class="max-w-full p-2 pr-[36px]">
        <AccountDropdown :display-current-workspace-name="true" />
      </div>
      <NavigationList
        v-if="permissionsStore.workspacePermissionsLoadingState === 'loaded'"
        data-test="sidebar-links"
      >
        <NavigationListItem
          :to="{ name: 'WorkspaceProjects' }"
          :force-active-state="someDialogIsOpen ? false : undefined"
          leading-icon="clock"
          leading-icon-active="clock-fill"
        >
          Recent projects
        </NavigationListItem>
        <template v-if="permissionsStore.workspacePermissions.create_projects">
          <NavigationListItem
            data-test="new-project-button-sidebar"
            :aria-labelledby="NEW_PROJECT_BUTTON_ID"
            leading-icon="circle-plus"
            leading-icon-active="circle-plus-fill"
            :class="isCreating && 'pointer-events-none select-none'"
            @click="onCreateProject"
          >
            <div class="flex w-full items-center justify-between">
              <div :id="NEW_PROJECT_BUTTON_ID">New project</div>
              <CircularProgress
                v-if="isCreating"
                size="xs"
              />
            </div>
          </NavigationListItem>
          <NavigationListItem
            data-test="explore-templates-sidebar"
            aria-label="Explore templates"
            leading-icon="templates"
            leading-icon-active="templates-fill"
            :force-active-state="templateModal.isOpen.value"
            @click="templateModal.open"
          >
            Explore templates
          </NavigationListItem>
          <NavigationListItem
            aria-label="Import CSV file"
            aria-haspopup="dialog"
            leading-icon="table"
            leading-icon-active="table-fill"
            :force-active-state="csvModal.isOpen.value"
            @click="csvModal.open"
          >
            Import CSV file
          </NavigationListItem>
        </template>

        <NavigationListItem
          aria-haspopup="dialog"
          leading-icon="folder"
          leading-icon-active="folder-fill"
          :force-active-state="libraryStore.dialogIsOpen"
          aria-labelledby="library-sidebar-button"
          @click="libraryStore.dialogIsOpen = !libraryStore.dialogIsOpen"
        >
          <div id="library-sidebar-button">Library</div>
        </NavigationListItem>
        <NavigationListItem
          v-if="
            permissionsStore.workspacePermissions.invite_members ||
            permissionsStore.workspacePermissions.add_members ||
            permissionsStore.workspacePermissions.remove_members ||
            permissionsStore.workspacePermissions.update_members
          "
          :to="{ name: 'WorkspaceSettingsUsers' }"
          data-test="invite-members-button-sidebar"
          leading-icon="user-plus"
          leading-icon-active="user-plus-fill"
        >
          <div class="flex w-full items-center justify-between">
            <div>Invite users</div>
            <div
              v-if="seatUsage && isFinite(seatUsage.limitValue)"
              class="flex items-center gap-2"
            >
              <div class="text-text-subtle">
                {{ seatUsage.limitUsage }}/{{ seatUsage.limitValue }}
              </div>
              <FlowerProgressVue
                aria-label="Seat usage"
                :color="seatUsageColor"
                :max="seatUsage.limitValue"
                :value="seatUsage.limitUsage"
              />
            </div>
          </div>
        </NavigationListItem>
      </NavigationList>
      <DividerLine
        color="subtle"
        :width="1"
        class="mt-2"
      />
      <div
        class="relative grow overflow-hidden after:pointer-events-none after:absolute after:bottom-0 after:block after:h-[40px] after:w-[calc(100%-1px)] after:bg-gradient-to-t after:from-surface-secondary after:to-background-transparent after:transition after:content-['']"
        :class="arrivedState.bottom ? 'after:opacity-0' : 'after:opacity-1'"
        data-test="all-projects-container"
      >
        <div
          class="h-full overflow-y-auto overflow-x-hidden py-2 scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
          data-test="project-sidebar-list"
        >
          <NavigationList>
            <template #header>All projects</template>
            <div
              v-for="(project, i) in sidebarProjects"
              :key="project?.id ?? i"
              class="h-7"
            >
              <NavigationListItem
                v-if="project"
                :disabled="projectIsDisabled(project)"
                class="group/item"
                data-test="project-sidebar-item"
                aria-label="Navigate to project"
                :force-active-state="someDialogIsOpen ? false : undefined"
                :to="{
                  name: 'WorkspaceProject',
                  params: { projectId: project.id },
                }"
              >
                <div class="flex w-full items-center justify-between gap-2">
                  <IconSprite
                    v-if="project.coverImageDownloadError"
                    class="w-4"
                    icon="file"
                    size="sm"
                  />
                  <CircularProgress
                    v-else-if="project.coverImageUploading"
                    size="xs"
                    class="animate-spin"
                  />
                  <ObjectURLImage
                    v-else
                    class="size-4 min-w-4 rounded-corner-4 object-cover"
                    :class="projectIsDisabled(project) ? 'opacity-50' : 'opacity-100'"
                    :url="project.coverImageUrls.low"
                    :loading="project.coverImageUploading ?? false"
                    @error="onImageSrcError(project)"
                  >
                    <template #fallback>
                      <IconSprite
                        class="w-4"
                        icon="file"
                        size="sm"
                      />
                    </template>
                  </ObjectURLImage>

                  <p class="truncate">
                    <ProjectName :project="project" />
                  </p>

                  <ProjectDropdown
                    class="ml-auto opacity-0 group-hover/item:opacity-100 data-[state=open]:opacity-100"
                    :workspace-id="workspaceId"
                    :project-id="project.id"
                  />
                </div>
              </NavigationListItem>
              <LoadingSkeleton
                v-else
                status
                class="box-border h-5 p-1"
              />
            </div>

            <NavigationListItem
              v-if="!hasWorkspaceProjects"
              leading-icon="plus"
              aria-label="Start a new project"
              @click="templateModal.open()"
            >
              Start a new project
            </NavigationListItem>
          </NavigationList>
        </div>
      </div>
      <template v-if="showUsage && billingStore.activePlan">
        <DividerLine
          color="subtle"
          :width="1"
        />
        <div class="grid grid-cols-2">
          <ProjectSidebarLimitUsage
            v-if="billingStore.fieldUsage"
            :usage="billingStore.fieldUsage"
            :billing-period="billingStore.activePlan.billingPeriod"
          />
          <ProjectSidebarLimitUsage
            v-if="billingStore.tokenUsage"
            :usage="billingStore.tokenUsage"
            :billing-period="billingStore.activePlan.billingPeriod"
          />
        </div>
        <DarwinButton
          v-if="
            billingStore.activePlan.name === 'free' &&
            permissionsStore.workspacePermissions.manage_billing
          "
          class="mx-3 mb-3"
          variant="neutral"
          size="sm"
          rounded
          :to="{ name: 'WorkspaceSettingsPlans' }"
          @click="onClick"
        >
          Go Pro
        </DarwinButton>
      </template>
    </div>
  </nav>
</template>
