import type { PropertyType } from '@/backend/types'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { useWorkspaceMembers } from '../WorkspaceSettings/useWorkspaceMembers'
import { useProject, type Field } from './useProject'
import { useResolveSubProjectRoute } from './useResolveSubProjectRoute'

type Serializer<Type extends PropertyType> = (field: Field<Type>) => string

const textSerializer: Serializer<'text'> = (field) => field.manualValue || field.toolValue || ''

const fileSerializer: Serializer<'file'> = (field) =>
  field.manualFilename || field.toolFilename || ''

const singleSelectSerializer: Serializer<'single_select'> = (field) =>
  field.manualValue?.at(0) || field.toolValue?.at(0) || ''

const multiSelectSerializer: Serializer<'multi_select'> = (field) => {
  if (field.manualValue) {
    return field.manualValue.join(', ')
  }

  if (field.toolValue) {
    return field.toolValue.join(', ')
  }

  return ''
}

const jsonSerializer: Serializer<'json'> = (field) => field.manualValue || field.toolValue || ''

const pdfSerializer: Serializer<'pdf'> = (field) => field.manualFilename || field.toolFilename || ''

const urlSerializer: Serializer<'url'> = (field) => field.manualValue || field.toolValue || ''

/**
 * Returns a function that serializes any field into a string representing
 * its value, to be used in clipboard operations.
 */
export const useSerializeFieldToText = () => {
  const workspaceMemberStore = useWorkspaceMembers()
  const userSerializer: Serializer<'user_select'> = (field) => {
    let userIds = field.manualValue
    if (!userIds || userIds.length === 0) {
      userIds = field.toolValue
    }

    if (!userIds || userIds.length === 0) {
      return ''
    }

    const users = userIds.reduce<string[]>((acc, userId) => {
      const user = workspaceMemberStore.workspaceMembers.find((member) => member.id === userId)
      if (!user) {
        return acc
      }

      const name = user.fullName || user.email || user.id

      return [...acc, name]
    }, [])

    return users.join(', ')
  }

  const projectStore = useProject()
  const workspacesStore = useWorkspaces()
  const resolveSubProjectRoute = useResolveSubProjectRoute()
  /**
   * Collections should be serialized to a URL that will open the collection.
   */
  const collectionSerializer: Serializer<'collection'> = (field) => {
    assertIsNotNullOrUndefined(workspacesStore.currentWorkspace, 'No current workspace')
    assertIsNotNullOrUndefined(projectStore.projectId, 'No current project')
    const property = projectStore.propertiesById[field.propertyId]
    if (!property) {
      return ''
    }

    if (property.type !== field.type) {
      throw new Error('Property type mismatch')
    }

    const subprojectId = property.config?.subprojectConfig.child_project_id
    if (!subprojectId) {
      return ''
    }
    const route = resolveSubProjectRoute({
      parentEntityId: field.entityId,
      parentProjectId: projectStore.projectId,
      subProjectId: subprojectId,
      workspaceId: workspacesStore.currentWorkspace.id,
    })

    return `${window.location.origin}${route.fullPath}`
  }

  const fileCollectionSerializer: Serializer<'file_collection'> = (field) => {
    assertIsNotNullOrUndefined(workspacesStore.currentWorkspace, 'No current workspace')
    assertIsNotNullOrUndefined(projectStore.projectId, 'No current project')
    const property = projectStore.propertiesById[field.propertyId]
    if (!property) {
      return ''
    }

    if (property.type !== field.type) {
      throw new Error('Property type mismatch')
    }

    const subprojectId = property.config?.subprojectConfig.child_project_id
    if (!subprojectId) {
      return ''
    }
    const route = resolveSubProjectRoute({
      parentEntityId: field.entityId,
      parentProjectId: projectStore.projectId,
      subProjectId: subprojectId,
      workspaceId: workspacesStore.currentWorkspace.id,
    })

    return `${window.location.origin}${route.fullPath}`
  }

  /**
   * Maps each field type to its serializer function. Is typed in such a way
   * that will cause a TS error if a new field type is added and not handled
   * here.
   */
  const fieldClipboardSerializers: { [T in Field['type']]: Serializer<T> } = {
    text: textSerializer,
    file: fileSerializer,
    json: jsonSerializer,
    multi_select: multiSelectSerializer,
    pdf: pdfSerializer,
    single_select: singleSelectSerializer,
    url: urlSerializer,
    user_select: userSerializer,
    collection: collectionSerializer,
    file_collection: fileCollectionSerializer,
  }

  const serializeField = <Type extends Field['type']>(field: Field<Type>) => {
    const serializer = fieldClipboardSerializers[field.type]
    return serializer(field)
  }

  return serializeField
}
