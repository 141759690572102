<script setup lang="ts">
import { VuePDF, usePDF } from '@tato30/vue-pdf'
import '@tato30/vue-pdf/style.css'
import { useElementSize } from '@vueuse/core'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import { useNaturalZoom } from '@/sharedComposables/useNaturalZoom'
import ScaleButtons from './ScaleButtons.vue'

const props = withDefaults(
  defineProps<{
    filePath: string
    storagePrefix?: string
    showSidebar?: boolean
  }>(),
  {
    storagePrefix: 'entityview-scale',
    groundingInfo: () => ({ sources: [], ocrPages: [] }),
    showPages: false,
  },
)

const route = useRoute()
const projectId = [route.params.projectId].flat()[0]
const propertyId = [route.params.propertyId].flat()[0]
const { pdf, pages, download } = usePDF(computed(() => props.filePath))

defineExpose({
  download,
})

const storageKey = computed(() => `${props.storagePrefix}-proj-${projectId}-prop-${propertyId}`)
const {
  onZoom,
  scrollContainer: pdfContainer,
  scrollContainerChild,
  scale,
  setScale,
} = useNaturalZoom(storageKey)

const { width: pdfContainerWidth } = useElementSize(pdfContainer)
</script>

<template v-if="filePath">
  <div
    class="relative size-full overflow-hidden p-1 pt-0"
    @wheel.ctrl.exact.prevent.stop="onZoom($event)"
    @wheel.meta.exact.prevent.stop="onZoom($event)"
  >
    <div class="flex size-full flex-col">
      <div class="size-full overflow-hidden rounded-corner-6 border border-border-subtle">
        <div
          id="pdf-container"
          ref="pdfContainer"
          class="go-scrollbar relative flex size-full flex-col flex-wrap items-center overflow-auto px-8 py-4 [contain:strict]"
        >
          <div
            id="pdf-container-child"
            ref="scrollContainerChild"
            class="inline-flex flex-col gap-4"
          >
            <div
              v-for="page in pages"
              :key="page"
              class="relative scroll-my-10"
              :data-page="page"
            >
              <VuePDF
                :page="page"
                :pdf="pdf"
                text-layer
                :scale="scale !== 'fit' ? scale : undefined"
                :width="scale === 'fit' ? pdfContainerWidth : undefined"
                :source="filePath"
                class="relative w-fit overscroll-none border-b transition last:border-none [&>*]:!block"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="pt-1">
        <ScaleButtons
          :scale="scale"
          full-width-mode
          @update:scale="setScale"
        />
      </div>
    </div>
  </div>
</template>
