<script setup lang="ts">
import { computed, ref, toRef, watch } from 'vue'

import { useFieldUpload } from '@/modules/Project/useFieldUpload'
import CircularProgress from '@/uiKit/CircularProgress.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { RouterLink } from 'vue-router'

import { useTableCellFocus } from './useTableCellFocus'

import FileUpload from '@/uiKit/FileUpload.vue'
import { toast } from '@/shared/toast'
import { useResolveSubProjectRoute } from './useResolveSubProjectRoute'
import { useSupportedMimeTypes } from './useSupportedMimeTypes'
import type { Field } from './useProject'
import { getFilesAndDirectoriesFromDragEvent, type Directory } from '@/shared/utils/file'

const props = defineProps<{
  workspaceId: string
  projectId: string
  field: Field<'file' | 'file_collection' | 'pdf'>
  filename: string | null
  isFocused: boolean
  isSelected: boolean
  pdfProjectId?: string | null
  url: string | null
}>()

const emit = defineEmits<{
  (e: 'uploaded', url: string): void
  (e: 'cleared' | 'blur'): void
  (e: 'upload:files', files: File[]): void
  (e: 'upload:directories', directories: Directory[]): void
}>()

const fileUpload = ref<InstanceType<typeof FileUpload>>()

const supportedMimeTypes = useSupportedMimeTypes()

const uploadStore = useFieldUpload()

const upload = computed(() =>
  uploadStore.getUpload(
    props.workspaceId,
    props.projectId,
    props.field.entityId,
    props.field.propertyId,
  ),
)
const uploadStatus = computed(() => upload.value?.status || 'idle')
const uploadFileName = computed(() => upload.value?.fileName)
const uploadProgress = computed(() => upload.value?.progress || 0)

watch(uploadStatus, (status) => {
  const completeStatuses: (typeof status)[] = ['done', 'error', 'idle', 'ready']
  const isComplete = completeStatuses.includes(status)
  if (isComplete && props.isFocused) {
    emit('blur')
  }
  if (upload.value?.status === 'error') {
    toast.error(upload.value.error)
    uploadStore.removeUpload(
      props.workspaceId,
      props.projectId,
      props.field.entityId,
      props.field.propertyId,
    )
  }
})

const dropZone = ref<HTMLElement | null>(null)
const cellContent = computed(() => props.filename || uploadFileName.value)

watch(
  () => props.isSelected,
  (isSelected) => (isSelected ? dropZone.value?.focus() : dropZone.value?.blur()),
  { immediate: true },
)

useTableCellFocus({
  cell: dropZone,
  isFocused: toRef(props, 'isFocused'),
  isSelected: toRef(props, 'isSelected'),
})

const resolveSubProjectRoute = useResolveSubProjectRoute()
const subProjectRoute = computed(() =>
  cellContent.value && props.pdfProjectId
    ? resolveSubProjectRoute({
        parentEntityId: props.field.entityId,
        subProjectId: props.pdfProjectId,
        parentProjectId: props.projectId,
        workspaceId: props.workspaceId,
      })
    : undefined,
)

const onDrop = async (e: DragEvent) => {
  const filesAndDirectories = await getFilesAndDirectoriesFromDragEvent(e)
  if (!filesAndDirectories) {
    return
  }

  /**
   * TODO: GO-2797
   * Handle dragging a combination of files+directories to an empty file cell
   */
  const directories = filesAndDirectories.filter((f): f is Directory => !(f instanceof File))
  if (directories && directories.length > 0) {
    emit('upload:directories', directories)
    /**
     * If the user dropped a selection of directories, then prevent the FileUpload
     * from processing the files and emitting a duplicate event.
     */
    e.stopImmediatePropagation()
    e.preventDefault()
  }
}
</script>

<template>
  <FileUpload
    ref="fileUpload"
    v-slot="{ rootProps, dropzoneProps, hiddenInputProps, isDragging }"
    :accept="supportedMimeTypes.join(', ')"
    :max-files="100"
    @change="$emit('upload:files', $event)"
  >
    <div
      v-bind="{ ...rootProps, ...$attrs }"
      class="w-full"
    >
      <div
        v-bind="dropzoneProps"
        ref="dropZone"
        :class="
          isDragging && 'rounded-corner-4 border border-border-focused bg-background-selected'
        "
        class="group/file flex h-8 shrink grow basis-0 flex-row content-center items-center justify-start truncate px-1.5 py-1 text-sm-12px-light text-text focus:outline-none"
        @drop="onDrop"
      >
        <input
          v-bind="hiddenInputProps"
          data-test="file-upload"
        />
        <div
          v-if="isDragging"
          class="truncate text-text-selected"
        >
          Drag and drop to upload content
        </div>

        <div v-else-if="subProjectRoute">
          <div
            data-table-cell-content
            class="line-clamp-1 flex h-8 basis-0 items-center truncate rounded-corner-4 px-1 py-2 focus-within:box-border"
          >
            <RouterLink
              title="Open PDF"
              :to="subProjectRoute"
              aria-label="Open PDF"
              @click.stop
            >
              <div
                class="flex h-5 items-center gap-1 rounded-corner-6 px-1 hover:bg-background-blue-subtle"
              >
                <IconSprite
                  class="text-icon-primary"
                  icon="file-fill"
                  size="xs"
                />
                <p
                  class="border border-background-transparent border-b-icon-gray-subtle text-sm-12px-default text-icon-primary hover:border-b-background-transparent"
                >
                  {{ cellContent }}
                </p>
              </div>
            </RouterLink>
          </div>
        </div>
        <div
          v-else-if="cellContent"
          class="inline-flex h-5 items-center justify-center gap-1 truncate rounded-md bg-background-gray-subtlest px-1"
        >
          <CircularProgress
            v-if="uploadStatus === 'uploading'"
            class="shrink-0"
            size="sm"
            :value="uploadProgress"
          />
          <div
            v-else
            class="flex size-3.5 items-center justify-center text-text-subtle"
            data-test="file-uploaded-icon"
          >
            <IconSprite icon="file" />
          </div>
          <div class="shrink truncate text-sm-12px-default text-text-subtle">
            {{ cellContent }}
          </div>
        </div>

        <div
          v-else
          class="inline-flex h-5 shrink items-center gap-0.5 truncate rounded-md bg-background-gray-subtlest px-1 group-hover/cell:visible hover:cursor-pointer hover:bg-background-gray-subtlest-hovered"
          :class="isSelected ? 'visible' : 'invisible'"
          role="button"
        >
          <div class="size-3.5 shrink-0 text-icon-subtle">
            <IconSprite icon="plus" />
          </div>
          <div class="truncate text-sm-12px-default text-text-subtle">
            Add file or drag and drop
          </div>
        </div>
      </div>
    </div>
  </FileUpload>
</template>
