<script setup lang="ts">
import { computed, ref } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import ClaimIndicator from './ClaimIndicator.vue'
import { useGroundingInteractions } from '@/sharedComposables/useGroundingInteractions'
import { type Claim } from '@/modules/Project/useGroundingStore'

const props = defineProps<{
  text: string
  claims: Claim[]
}>()

const sections = computed<Array<{ sectionText: string; claim?: Claim }>>(() => {
  const { acc: claimedSections, offset: lastClaimEnd } = props.claims.reduce<{
    acc: Array<{ sectionText: string; claim?: Claim }>
    offset: number
  }>(
    ({ acc, offset }, claim) => {
      if (offset < claim.start) acc.push({ sectionText: props.text.slice(offset, claim.start) })
      acc.push({ sectionText: props.text.slice(claim.start, claim.end), claim })
      return { acc, offset: claim.end }
    },
    { acc: [], offset: 0 },
  )

  if (lastClaimEnd < props.text.length) {
    claimedSections.push({ sectionText: props.text.slice(lastClaimEnd) })
  }

  return claimedSections
})

const container = ref<HTMLElement | null>(null)

const { clickClaimPill, selected } = useGroundingInteractions({
  onSourcePillClick: ({ sourceId }) => {
    if (!container.value) return
    const claim = container.value.querySelector(`[data-claim="${sourceId}"]`)
    claim?.scrollIntoView({ behavior: 'smooth' })
    selected.value = { sourceId }
  },
})
</script>

<template>
  <div
    ref="container"
    class="contents whitespace-pre-wrap"
  >
    <span
      v-for="({ sectionText, claim }, sectionIndex) in sections"
      :key="sectionIndex"
      v-on-click-outside="() => (selected = null)"
      class="scroll-my-4 transition-all"
      :class="selected && selected.claimId !== claim?.id && 'pointer-events-none opacity-25'"
    >
      <span class="pointer-events-none">{{ sectionText }}</span>
      <slot
        name="claim"
        :claim="claim"
      >
        <template v-if="claim">
          <ClaimIndicator
            v-for="sourceId in claim.sources"
            :key="`${claim.id}-${sourceId}`"
            class="-translate-y-px"
            :source-id="sourceId"
            :data-claim="sourceId"
            @click="
              () => {
                clickClaimPill(claim.id, sourceId)
                selected = { claimId: claim.id, sourceId }
              }
            "
          />
        </template>
      </slot>
    </span>
  </div>
</template>
