<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { PropertyType } from '@/backend/types'
import { useFieldUpload } from './useFieldUpload'

import DotIndicator from '@/uiKit/DotIndicator.vue'

import { toast } from '@/shared/toast'
import { useSetFieldValue } from '@/shared/useSetFieldValue'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useWelcomeTour } from '../WelcomeTour/useWelcomeTour'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { FIELD_TOOL_LABELS } from '../WorkspaceSettings/propertyConfig'
import CollectionCell from './CollectionCell.vue'
import FileCell from './FileCell.vue'
import { TOOL_ICON } from './icons'
import ProjectTableCellActions from './ProjectTableCellActions.vue'
import ProjectTableCellTooltip from './ProjectTableCellTooltip.vue'
import { resolveFilename } from './resolveFilename'
import SelectCell from './SelectCell.vue'
import StatusCell from './StatusCell.vue'
import TableCell from './TableCell.vue'
import TextCell from './TextCell.vue'
import URLTypeFocusedCell from './URLTypeFocusedCell.vue'
import { useFieldStatusIndicator } from './useFieldStatusIndicator'
import { useGroundingStore } from './useGroundingStore'
import { providePinnedProp } from './usePinnedColumn'
import { useProject, type Property } from './useProject'
import { useProjectTooltip } from './useProjectTooltip'
import { useTable } from './useTable'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import {
  PINNED_SELECTED_CELL_Z_INDEX,
  SELECTED_CELL_Z_INDEX,
  useTableZIndices,
} from './useTableZIndices'
import { useOpenGrounding } from './useOpenGrounding'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { useTableCellPreview } from './useTableCellPreview'
import ProjectTableCellPreviewControl from './ProjectTableCellPreviewControl.vue'
import { isFileField, isFileProperty } from '@/shared/utils/typeGuards'
import FileCollectionCell from './FileCollectionCell.vue'
import { setEntityMetadata } from '@/backend/setEntityMetadata'

const props = defineProps<{
  rowIndex: number
  colIndex: number
  rowIsSelected: boolean
}>()

const emit = defineEmits<{ (e: 'next', event?: KeyboardEvent): void }>()

const tableStore = useTable()
const projectStore = useProject()
const workspacesStore = useWorkspaces()

const entity = computed(() => projectStore.activeView?.entities?.[props.rowIndex])
const property = computed<Property | undefined>(
  () => projectStore.visibleProperties[props.colIndex],
)
const isPinned = providePinnedProp(computed(() => property.value?.id))

const field = computed(() =>
  propertyId.value ? entity.value?.fields.get(propertyId.value) : undefined,
)

const propertyId = computed(() => property.value?.id)
const entityId = computed(() => entity.value?.id || null)

const permissionsStore = usePermissionsStore()
const setFieldValue = useSetFieldValue()
const save = async (newValue: string | string[] | null) => {
  if (!permissionsStore.currentProjectPermissions.update_entities) {
    return
  }
  assertIsNotNullOrUndefined(entityId.value, 'No entity found when saving field value')

  tableStore.focusedCell = null
  const isSelect =
    property.value?.type === PropertyType.multi_select ||
    property.value?.type === PropertyType.single_select ||
    property.value?.type === PropertyType.user_select

  // This function can be called when the user clicks away from a cell they have edited.
  // In this case we don't want to move to the next cell, as they might have clicked a
  // cell they want to edit.
  if (isSelected.value && !isSelect) {
    emit('next')
  }

  if (
    !projectStore.projectId ||
    !workspacesStore.currentWorkspace ||
    !propertyId.value ||
    !field.value
  ) {
    return
  }

  const success = await setFieldValue({
    workspaceId: workspacesStore.currentWorkspace.id,
    projectId: projectStore.projectId,
    fields: {
      [propertyId.value]: { field: field.value, newValue },
    },
    entityId: entityId.value,
  })
  if (!success) {
    toast.error('Failed to save value, please try again.')
  }
}

const saveManualName = async (newName: string) => {
  if (
    !projectStore.projectId ||
    !workspacesStore.currentWorkspace ||
    !propertyId.value ||
    !entityId.value
  ) {
    return
  }

  const success = await setEntityMetadata({
    workspaceId: workspacesStore.currentWorkspace.id,
    projectId: projectStore.projectId,
    entityId: entityId.value,
    propertyIdOrSlug: propertyId.value,
    fieldName: newName,
  })
  if (!success) {
    toast.error('Failed to save, please try again.')
  }
}

const { isModelOutput } = useFieldStatusIndicator(field)
const draftValue = computed(
  () => field.value && projectStore.draftFields[field.value.entityId]?.[field.value.propertyId],
)

const fieldValue = computed(() => {
  return draftValue.value || field.value?.manualValue || field.value?.toolValue || ''
})
const filename = computed(() =>
  field.value?.type === 'file' || field.value?.type === 'pdf' ? resolveFilename(field.value) : null,
)

const outOfDate = computed(() => {
  return (
    field.value?.status === 'complete' &&
    field.value?.manualValue === null &&
    field.value?.propertyHash !== property.value?.hash
  )
})

// ref + watch means orders of magnitued better performance than computed
// add an onRenderTriggered callback with a console.log to understand the difference
const isSelected = ref(false)
watch(
  () => tableStore.selectedCell,
  () => {
    isSelected.value = tableStore.isCellSelected({
      rowIndex: props.rowIndex,
      colIndex: props.colIndex,
    })
  },
)

const isFocused = ref(false)
watch(
  () => tableStore.focusedCell,
  () => {
    isFocused.value = tableStore.isCellFocused(props.rowIndex, props.colIndex)
  },
)

const isInSelectedRange = ref(false)
watch(
  () => tableStore.selectedRange,
  () => {
    isInSelectedRange.value = tableStore.isCellInSelectedRange({
      rowIndex: props.rowIndex,
      colIndex: props.colIndex,
    })
  },
)

const { captureAnalyticsEvent } = useAnalytics()

const uploadStore = useFieldUpload()

const deleteFile = async () => {
  if (
    !projectStore.projectId ||
    !workspacesStore.currentWorkspace ||
    !entityId.value ||
    !propertyId.value ||
    !field.value
  ) {
    return
  }

  // if the file is being uploaded, remove it from the upload queue
  // before deleting it
  if (field.value?.status == 'uploading') {
    uploadStore.removeUpload(
      workspacesStore.currentWorkspace.id,
      projectStore.projectId,
      entityId.value,
      propertyId.value,
    )
  }

  const success = await setFieldValue({
    workspaceId: workspacesStore.currentWorkspace.id,
    projectId: projectStore.projectId,
    fields: {
      [propertyId.value]: { field: field.value, newValue: null },
    },
    entityId: entityId.value,
  })
  if (success) {
    captureAnalyticsEvent(ANALYTICS_EVENT.FILE_DELETED)
  }
}

const subProjectId = computed(() => {
  if (property.value?.type === 'pdf') {
    const config = property.value.config
    return config ? config.subprojectConfig.child_project_id : null
  }
  if (property.value?.type === 'collection') {
    const config = property.value.config
    return config ? config.subprojectConfig.child_project_id : null
  }
  return null
})

const onNext = (e: KeyboardEvent) => {
  isFocused.value = false
  isSelected.value = false
  emit('next', e)
}

/** Change focus to this cell */
const onFocus = () => {
  if (!permissionsStore.currentProjectPermissions.update_entities) {
    return
  }
  tableStore.focusCell(props.rowIndex, props.colIndex)
}

/** Change the selection to this cell */
const onSelect = () => {
  tableStore.selectCell(props.rowIndex, props.colIndex)
}

const onClickFileCell = () => {
  // If the cell is a file cell, then the upload dialog should always appear
  // on click. To do this, we should select and focus the cell.
  if (!field.value?.manualValue) {
    tableStore.focusedCell = null
    onSelect()
    onFocus()
  }
}

const onSpace = () => {
  // Special behaviour for file cells - space should open the upload dialog
  if (property.value?.type === PropertyType.file) {
    onFocus()
  }
}

/**
 * Accessible grid cell attributes to add to every type of cell
 */
const gridCellAttributes = computed(() => {
  return {
    role: 'gridcell',
    /**
     * Add 2 to the indices because:
     * 1. (+1) The aria- values are 1-indexed
     * 2. (+1) To account for the header row and column
     */
    'aria-colindex': props.colIndex + 2,
    'aria-rowindex': props.rowIndex + 2,
    'aria-selected': isInSelectedRange.value ? 'true' : 'false',
    'aria-readonly': isSelected.value ? 'false' : 'true',
    tabIndex: '0',
  }
})

const viewId = computed(() => projectStore.activeView?.id ?? projectStore.mainView?.id)

/**
 * Is called when a cell emits the blur event. It might not actually blur the focused
 * DOM element, but it will update the store's internal 'focus' state, which will
 * indirectly cause the focused DOM element to blur if it is no longer selected.
 */
const onBlur = () => {
  if (isFocused.value) {
    // Unfocus the cell but keep it selected
    tableStore.focusedCell = null
    return
  }

  if (isSelected.value) {
    // Unselect the cell
    tableStore.selectedRange = null
    tableStore.selectedCell = null
  }
}

const { isCorrectedModelOutput, lastCorrectedBy, onLockField } = useProjectTooltip({
  field,
  entity,
  propertyId,
})

const onTooltipReset = () => {
  save(null)
}

const isWaitingForConfiguration = computed(() => {
  return property.value?.tool !== 'manual' && property.value?.inputs.length === 0
})

const isSelectOrMultiSelect = computed(
  () =>
    property.value?.type === PropertyType.single_select ||
    property.value?.type === PropertyType.multi_select,
)

// in this very specific case for selects
const showDotIndicatorForSelects = computed(
  () =>
    isSelectOrMultiSelect.value &&
    field.value &&
    (field.value.status === 'computing' || field.value?.groundTruth || outOfDate.value) &&
    !isSelected.value,
)

// foe every other field
const showDotIndicator = computed(
  () =>
    field.value &&
    !isSelectOrMultiSelect.value &&
    (field.value.status === 'computing' ||
      field.value.status === 'error' ||
      field.value.errorMessage ||
      field.value?.groundTruth ||
      outOfDate.value) &&
    !isSelected.value,
)

const isTooltipVisible = computed(() => {
  // The tooltip has different visibility rules depending on the enablement of the STAR_FIELDS feature flag
  return (
    // In all cases, make sure that the cell is selected
    isSelected.value &&
    // Don't show if a range of cells is selected
    !tableStore.hasSelectedMultipleCells &&
    // If the tool is not manual, show the tooltip
    ((property.value && property.value.tool !== 'manual') ||
      // Otherwise, show the tooltip if the cell is a corrected model output
      isCorrectedModelOutput.value)
  )
})

const welcomeTour = useWelcomeTour()

const { hasGroundingClaim } = useGroundingStore()
const { openGrounding } = useOpenGrounding()

const isSelectCell = computed(() => {
  return (
    property.value?.type === PropertyType.multi_select ||
    property.value?.type === PropertyType.single_select ||
    property.value?.type === PropertyType.user_select
  )
})

const reasoning = computed(() => {
  return field.value?.type === PropertyType.single_select ||
    field.value?.type === PropertyType.multi_select
    ? field.value?.metadata?.motivation
    : null
})

const zIndices = useTableZIndices()

const { previewEdges, previewState, shouldShowPreviewPopover } = useTableCellPreview({
  cell: computed(() => ({ colIndex: props.colIndex, rowIndex: props.rowIndex })),
  hasDraftValue: computed(() => Boolean(draftValue.value)),
})

const isDragStartCell = computed(() => {
  return (
    tableStore.selectedRange?.dragStartCell?.colIndex === props.colIndex &&
    tableStore.selectedRange?.dragStartCell?.rowIndex === props.rowIndex
  )
})
</script>

<template>
  <TableCell
    :is-focused="isFocused"
    :is-selected="isSelected || isDragStartCell"
    :is-in-selected-range="isInSelectedRange || isDragStartCell"
    :row-is-selected="rowIsSelected"
    :loading="entity === undefined"
    :style="{
      zIndex: isSelected
        ? isPinned
          ? PINNED_SELECTED_CELL_Z_INDEX
          : SELECTED_CELL_Z_INDEX
        : isPinned
          ? zIndices.zIndex.pinnedColumn
          : undefined,
    }"
    class="[&>*]:transition-opacity [&>*]:duration-250"
    :class="[
      rowIsSelected && 'bg-background-selected',
      (previewState === 'outside' || previewState === 'inside:unchanged') && '[&>*]:opacity-30',
      previewEdges?.length &&
        'before:absolute before:-left-0.5 before:-top-0.5 before:z-1 before:!size-[calc(100%+4px)] before:border-dashed before:border-border-focused',
      previewEdges?.includes('top') && 'before:border-t-2',
      previewEdges?.includes('right') && 'before:border-r-2',
      previewEdges?.includes('bottom') && 'before:border-b-2',
      previewEdges?.includes('left') && 'before:border-l-2',
      previewEdges &&
        previewEdges.includes('left') &&
        previewEdges.includes('top') &&
        'before:rounded-tl-corner-8',
      previewEdges &&
        previewEdges.includes('left') &&
        previewEdges.includes('bottom') &&
        'before:rounded-bl-corner-8',
      previewEdges &&
        previewEdges.includes('right') &&
        previewEdges.includes('top') &&
        'before:rounded-tr-corner-8',
      previewEdges &&
        previewEdges.includes('right') &&
        previewEdges.includes('bottom') &&
        'before:rounded-br-corner-8',
    ]"
  >
    <ProjectTableCellPreviewControl v-if="shouldShowPreviewPopover" />
    <ProjectTableCellTooltip
      v-if="
        isTooltipVisible && field?.toolValue && field.toolValueUpdatedBy && property?.id && entityId
      "
      :last-corrected-by="isCorrectedModelOutput ? lastCorrectedBy : undefined"
      :tool="
        isCorrectedModelOutput
          ? undefined
          : {
              name: FIELD_TOOL_LABELS[field.toolValueUpdatedBy],
              icon: TOOL_ICON[field.toolValueUpdatedBy],
            }
      "
      :field-has-ground-truth="field?.groundTruth"
      :has-grounding-claim="hasGroundingClaim(field)"
      :is-corrected-model-output="isCorrectedModelOutput"
      :reasoning="reasoning"
      @open="openGrounding({ propertyId: property.id, entityId })"
      @lock="onLockField"
      @reset="onTooltipReset"
    />

    <div
      v-if="
        entityId &&
        projectStore.projectId &&
        property &&
        workspacesStore.currentWorkspace &&
        (props.colIndex === 0 || (filename && property.tool === 'manual') || subProjectId)
      "
      class="invisible absolute top-1/2 z-1 flex h-8 -translate-y-1/2 items-center justify-center px-1 py-1.5"
      :class="[
        props.colIndex === 0 ? 'group-hover/row:visible' : 'group-hover/cell:visible',
        showDotIndicator ? 'right-5' : 'right-0',
      ]"
    >
      <ProjectTableCellActions
        v-if="welcomeTour.status !== 'IN_PROGRESS'"
        :entity-id="entityId"
        :workspace-id="workspacesStore.currentWorkspace.id"
        :project-id="projectStore.projectId"
        :property-id="property.id"
        :view-id="viewId"
        :col-index="props.colIndex"
        :filename="filename"
        @delete="deleteFile"
        @open:grounding-modal="openGrounding({ propertyId: property.id, entityId })"
      />
    </div>
    <div
      class="flex size-full flex-row items-center"
      :class="[
        isModelOutput && !(isSelectCell && isSelected) && 'bg-background-stages-model-subtle',
      ]"
    >
      <URLTypeFocusedCell
        v-if="!isFocused && field?.type === 'url' && fieldValue"
        :error="field?.errorMessage"
        :status="field?.status"
        :manual-value="fieldValue"
        :metadata="field?.metadata"
        :is-selected="isSelected"
        v-bind="gridCellAttributes"
        @edit="onFocus"
        @keydown.enter.stop.prevent="onFocus"
      />
      <StatusCell
        v-else-if="
          field &&
          (field.status === 'error' ||
            field.status === 'computing' ||
            field.status === 'waiting') &&
          // URL types can be corrected when focused via the TextCell
          !(property?.type === 'url' && isFocused)
        "
        v-bind="gridCellAttributes"
        :status="field.status"
        :is-selected="isSelected"
        :error="field.errorMessage"
        :type="property?.type"
        :manual-value="field.manualValue"
        @keydown.enter.stop.prevent="onFocus"
      />
      <TextCell
        v-else-if="
          [PropertyType.text, PropertyType.json, PropertyType.url].some(
            (pt) => pt === property?.type,
          ) && typeof fieldValue === 'string'
        "
        v-bind="gridCellAttributes"
        :is-selected="isSelected"
        :has-selected-range="tableStore.hasSelectedMultipleCells"
        :json="property?.type === 'json'"
        :is-focused="isFocused"
        :value="fieldValue"
        :type="property?.type"
        :is-model-output="isModelOutput"
        :col-index="colIndex"
        :is-waiting-for-configuration="isWaitingForConfiguration"
        :is-disabled="!permissionsStore.currentProjectPermissions.update_entities"
        @keydown.enter.stop.prevent="onFocus"
        @submit="save($event)"
        @next="onNext"
        @blur="onBlur"
        @focus="onFocus"
      />

      <CollectionCell
        v-else-if="
          workspacesStore.currentWorkspace &&
          entityId &&
          projectStore.projectId &&
          subProjectId &&
          property?.type === 'collection' &&
          typeof fieldValue === 'string'
        "
        v-bind="gridCellAttributes"
        :title="fieldValue"
        :is-selected="isSelected"
        :is-focused="isFocused"
        :value="
          property.tool === 'manual' && field?.type === 'collection' && field?.subprojectPreview
            ? String(field?.subprojectPreview.entityCount)
            : fieldValue
        "
        :tool="property?.tool"
        :is-model-output="isModelOutput"
        :col-index="colIndex"
        :entity-id="entityId"
        :workspace-id="workspacesStore.currentWorkspace.id"
        :project-id="projectStore.projectId"
        :view-id="projectStore.activeView?.id"
        :collection-project-id="subProjectId"
        :name="field?.type === 'collection' && field?.manualName ? field?.manualName : undefined"
        @save-name="saveManualName($event)"
      />

      <FileCollectionCell
        v-else-if="
          property?.type === 'file_collection' &&
          field?.type === 'file_collection' &&
          projectStore.projectId &&
          workspacesStore.currentWorkspace
        "
        :field="field"
        :property="property"
        :workspace-id="workspacesStore.currentWorkspace.id"
        v-bind="gridCellAttributes"
        :is-focused="isFocused"
        :view-id="projectStore.activeView?.id"
        :is-selected="isSelected"
        :col-index="colIndex"
        :has-selected-range="tableStore.hasSelectedMultipleCells"
        @cleared="save(null)"
        @keydown.space="onSpace"
        @keydown.enter.stop.prevent="onFocus"
        @blur="onBlur"
      />

      <FileCell
        v-else-if="
          isFileField(field) &&
          isFileProperty(property) &&
          projectStore.projectId &&
          workspacesStore.currentWorkspace &&
          entityId &&
          propertyId &&
          field
        "
        :field="field"
        :property="property"
        :workspace-id="workspacesStore.currentWorkspace.id"
        v-bind="gridCellAttributes"
        :project-id="projectStore.projectId"
        :is-focused="isFocused"
        :is-selected="isSelected"
        :property-id="propertyId"
        :has-selected-range="tableStore.hasSelectedMultipleCells"
        :filename="filename"
        :url="field.manualValue"
        :pdf-project-id="subProjectId"
        @cleared="save(null)"
        @keydown.space="onSpace"
        @keydown.enter.stop.prevent="onFocus"
        @click="onClickFileCell"
        @blur="onBlur"
      />

      <SelectCell
        v-else-if="
          property &&
          (property.type === PropertyType.single_select ||
            property.type === PropertyType.multi_select ||
            property.type === PropertyType.user_select)
        "
        :property="property"
        :has-reasoning="!!reasoning"
        :is-focused="isFocused"
        :is-selected="isSelected"
        :value="fieldValue"
        :property-id="propertyId"
        :has-selected-range="tableStore.hasSelectedMultipleCells"
        :is-model-output="isModelOutput"
        v-bind="gridCellAttributes"
        @submit="save($event)"
        @cleared="save(null)"
        @keydown.space="onSpace"
        @keydown.enter.stop.prevent="onFocus"
        @focus="onFocus"
      />

      <DotIndicator
        v-if="(showDotIndicator || showDotIndicatorForSelects) && field"
        :field="field"
        :out-of-date="outOfDate"
        class="mr-2"
      />
    </div>
    <div />
  </TableCell>
</template>
