import { PropertyType } from '@/backend/types'
import { FeatureFlag, useFeatureFlags } from '@/modules/App/useFeatureFlags'
import { FIELD_TYPES_OPTIONS } from '@/modules/WorkspaceSettings/propertyConfig'
import { computed } from 'vue'

export const useFieldTypeOptions = () => {
  const isPdfTypeEnabled = useFeatureFlags(FeatureFlag.PDF_PROPERTY_TYPE)
  const isUserSelectEnabled = useFeatureFlags(FeatureFlag.WORK_ASSIGNENT)
  const isFileCollectionEnabled = useFeatureFlags(FeatureFlag.INPUT_COLLECTIONS)

  return computed(() =>
    FIELD_TYPES_OPTIONS.map((t) => ({ id: t.type, data: t })).filter((t) => {
      if (t.data.type === PropertyType.pdf && !isPdfTypeEnabled.value) {
        return false
      }

      if (t.data.type === PropertyType.user_select && !isUserSelectEnabled.value) {
        return false
      }

      if (t.data.type === PropertyType.file_collection && !isFileCollectionEnabled.value) {
        return false
      }

      return true
    }),
  )
}
