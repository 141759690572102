import type { MultiSelectProperty, Property, SingleSelectProperty, View } from './useProject'

export const getSelectPropertyOptionColor = (
  property: SingleSelectProperty | MultiSelectProperty,
  optionValue: string,
) => {
  return property.config?.options.find((option) => option.value === optionValue)?.color
}

export const getViewColors = (view: View, properties: Property[]) => {
  return view.filters.flatMap((filter) => {
    const property = properties.find((property) => property.id === filter.property_id)
    if (!property) return []

    return getSelectPropertyOptionColor(
      property as SingleSelectProperty,
      filter.select_option_value,
    )
  })
}

export const getViewColor = (view: View, properties: Property[]) => {
  const colors = getViewColors(view, properties)

  if (colors.length === 1) {
    return colors[0]
  }

  return 'rainbow-17'
}

import type { PropertyType } from '@/backend/types'
import PropertyTypeTooltipCollection from '@/illustrations/property-type-tooltip-collection.svg'
import PropertyTypeTooltipFile from '@/illustrations/property-type-tooltip-file.svg'
import PropertyTypeTooltipJson from '@/illustrations/property-type-tooltip-json.svg'
import PropertyTypeTooltipMultiSelect from '@/illustrations/property-type-tooltip-multi-select.svg'
import PropertyTypeTooltipPdf from '@/illustrations/property-type-tooltip-pdf.svg'
import PropertyTypeTooltipSingleSelect from '@/illustrations/property-type-tooltip-single-select.svg'
import PropertyTypeTooltipText from '@/illustrations/property-type-tooltip-text.svg'
import PropertyTypeTooltipUrl from '@/illustrations/property-type-tooltip-url.svg'
import PropertyTypeTooltipUserSelect from '@/illustrations/property-type-tooltip-user-select.svg'

export const PROPERTY_TYPE_TOOLTIP_DATA: Record<
  PropertyType,
  { illustrationComponent: string; description: string }
> = {
  file: {
    illustrationComponent: PropertyTypeTooltipFile,
    description:
      'Upload and manage large files, from PDFs to CSVs. Use files as inputs for AI models.',
  },
  text: {
    illustrationComponent: PropertyTypeTooltipText,
    description:
      'Generate freeform text, perfect for tasks like summarization and open ended analysis.',
  },
  single_select: {
    illustrationComponent: PropertyTypeTooltipSingleSelect,
    description:
      'Limit model outputs to a single choice from a set list. Ideal for classification tasks.',
  },
  multi_select: {
    illustrationComponent: PropertyTypeTooltipMultiSelect,
    description:
      'Allow model outputs to include multiple choices from a set list. Ideal for classification tasks requiring more than one output.',
  },
  user_select: {
    illustrationComponent: PropertyTypeTooltipUserSelect,
    description:
      'Assign users to entities for human-in-the-loop workflows, ensuring human oversight in tasks requiring review.',
  },
  json: {
    illustrationComponent: PropertyTypeTooltipJson,
    description: 'Output data in JSON format. Perfect for tasks that need a structured output.',
  },
  pdf: {
    illustrationComponent: PropertyTypeTooltipPdf,
    description:
      'The PDF type is specifically designed for handling large PDF documents. It supports viewing, processing, and extracting information from large PDF files within your project.',
  },
  collection: {
    illustrationComponent: PropertyTypeTooltipCollection,
    description:
      'Convert inputs into a structured table of sub-entities, enabling deeper levels of analysis. Ideal for tasks requiring one level deeper analysis, with a structured output.',
  },
  file_collection: {
    illustrationComponent: PropertyTypeTooltipCollection,
    description: '',
  },
  url: {
    illustrationComponent: PropertyTypeTooltipUrl,
    description:
      'The URL tool extracts data from web pages, enabling you to use URLs as inputs for AI models.',
  },
}
