<script setup lang="ts">
import { parseMatcherName } from '@/modules/Project/useFilters'
import type { TextProperty } from '@/modules/Project/useProject'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import IconButton from '@/uiKit/IconButton.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import ModalDialog from '@/uiKit/ModalDialog.vue'
import SelectDropdown from '@/uiKit/SelectDropdown.vue'
import SelectDropdownTrigger from '@/uiKit/SelectDropdownTrigger.vue'
import TextArea from '@/uiKit/TextArea.vue'
import { ref } from 'vue'
import type { MatcherName, TextFilterMatcher, TextValueFilter } from './types'

const props = defineProps<{
  filter: TextValueFilter
  isOpen: boolean
  property: TextProperty
  teleportTo?: string
}>()

const emit = defineEmits<{
  (e: 'close', event: Event): void
  (e: 'update', data: { caseSensitive: boolean; matcherName: MatcherName; values: string[] }): void
}>()

const textFilter = ref(props.filter.matcher.values[0])

const matcherName = ref<MatcherName>(props.filter.matcher.name)
const caseSensitive = ref<boolean>(props.filter.matcher.case_sensitive ?? true)

const onSave = (event: Event) => {
  emit('update', {
    caseSensitive: caseSensitive.value,
    matcherName: matcherName.value,
    values: [textFilter.value],
  })
  emit('close', event)
}

const TEXT_MATCHER_NAMES: TextFilterMatcher[] = [
  'property_contains_any_of',
  'property_contains_none_of',
  'property_any_of',
  'property_none_of',
]

const matcherItems = TEXT_MATCHER_NAMES.map((matcherName) => ({
  id: matcherName,
  data: { id: matcherName, label: parseMatcherName({ matcherName, type: 'text' }) },
}))

const caseSensitivenessItems = [
  { id: 'case_sensitive', data: { id: 'case_sensitive', label: 'case sensitive' } },
  { id: 'case_insensitive', data: { id: 'case_insensitive', label: 'case insensitive' } },
]

const DIALOG_ID = 'text-filter-dialog'
</script>

<template>
  <ModalDialog
    :open="isOpen"
    :to="teleportTo"
    @close="emit('close', $event)"
  >
    <div
      :id="DIALOG_ID"
      class="flex w-[480px] flex-col"
    >
      <!-- Header -->
      <div class="flex items-center gap-2 p-2 pl-11 text-center">
        <h2 class="grow text-sm-12px-default text-text-subtle">
          {{ property.name }}
        </h2>
        <IconButton
          icon="close"
          variant="transparent"
          size="md"
          @click="emit('close', $event)"
        />
      </div>
      <DividerLine
        color="subtle"
        direction="horizontal"
      />
      <!-- Body -->
      <div class="flex flex-col gap-1.5 p-5">
        <div class="flex w-full gap-1">
          <SelectDropdown
            class="w-full"
            :teleport-to="`#${DIALOG_ID}`"
          >
            <template #trigger>
              <SelectDropdownTrigger>{{
                parseMatcherName({ matcherName, type: 'text' })
              }}</SelectDropdownTrigger>
            </template>
            <template #dropdown="{ close }">
              <ListMenu
                ref="typeDropdown"
                data-select-dropdown-menu
                :items="matcherItems"
                class="min-w-[200px]"
              >
                <template #item="{ key, item, active, setActiveItem }">
                  <ListMenuItem
                    :label="item.data.label"
                    :active="active"
                    :aria-selected="active"
                    default-hover-disabled
                    @mousemove="setActiveItem(key)"
                    @select="(matcherName = item.data.id), close()"
                  />
                </template>
              </ListMenu>
            </template>
          </SelectDropdown>
          <SelectDropdown
            class="w-full"
            :teleport-to="`#${DIALOG_ID}`"
          >
            <template #trigger>
              <SelectDropdownTrigger>{{
                caseSensitive ? 'case sensitive' : 'case insensitive'
              }}</SelectDropdownTrigger>
            </template>
            <template #dropdown="{ close }">
              <ListMenu
                ref="typeDropdown"
                :items="caseSensitivenessItems"
                class="min-w-[200px]"
                data-select-dropdown-menu
              >
                <template #item="{ key, item, active, setActiveItem }">
                  <ListMenuItem
                    :label="item.data.label"
                    :active="active"
                    :aria-selected="active"
                    default-hover-disabled
                    @mousemove="setActiveItem(key)"
                    @select="(caseSensitive = item.data.id === 'case_sensitive'), close()"
                  />
                </template>
              </ListMenu>
            </template>
          </SelectDropdown>
        </div>
        <TextArea
          class="text-sm-12px-light"
          :value="textFilter"
          size="md"
          aria-label="Content"
          placeholder="Enter content"
          @input="textFilter = $event"
        />
      </div>
      <DividerLine
        color="subtle"
        direction="horizontal"
      />
      <div class="flex items-center justify-end gap-2 p-2">
        <DarwinButton
          variant="neutral"
          size="md"
          @click="emit('close', $event)"
        >
          Cancel
        </DarwinButton>
        <DarwinButton
          variant="black"
          size="md"
          @click="onSave"
        >
          Save
        </DarwinButton>
      </div>
    </div>
  </ModalDialog>
</template>
