<script setup lang="ts">
import { ref, watch, toRef, computed, nextTick } from 'vue'

import { useTableCellFocus } from './useTableCellFocus'
import { RouterLink } from 'vue-router'
import IconSprite from '@/uiKit/IconSprite.vue'
import { useResolveSubProjectRoute } from './useResolveSubProjectRoute'
import { FeatureFlag, useFeatureFlags } from '@/modules/App/useFeatureFlags'

const props = defineProps<{
  workspaceId: string
  projectId: string
  collectionProjectId: string
  entityId: string
  tool: string
  value: string
  name?: string
  isFocused: boolean
  isSelected: boolean
  isModelOutput?: boolean
  viewId?: string
}>()

const emit = defineEmits<{
  (e: 'saveName', value: string): void
}>()

const isManualCollectionEnabled = useFeatureFlags(FeatureFlag.INPUT_COLLECTIONS, true)

const target = ref<HTMLElement>()

/**
 * The value of the contenteditable when the cell enters edit mode. This will
 * either be:
 * - props.value if the user has pressed enter
 * - an empty string if the user has pressed backspaces, delete, or a printable character
 * This needs to be set whenever the cell enters edit mode, otherwise the contenteditable
 * 'remembers' its previous value, which causes bugs when escaping.
 */
const startValue = ref(props.value)
const startName = ref(props.name ?? '')

/**
 * The current value of the text inside of the contenteditable. Is used:
 * 1. To emit an updated value with the save event
 * 2. For optimistic UI when submitting changes
 */
const localValue = ref('')
const localName = ref('')

// A stateless approach to the editable, by directly binding to the prop.value and emiting change,
// does not work, because we lose focus on every edit and re-update.
// So we have to store a local value.

const collectionNameRef = ref<HTMLInputElement | null>(null)
const isEditMode = ref(false)

const onRenameSave = () => {
  if (localName.value !== startName.value) {
    emit('saveName', localName.value)
    startName.value = localName.value
  }
  isEditMode.value = false
}

watch(
  () => props.value,
  () => {
    localValue.value = props.value
    startValue.value = props.value
  },
  { immediate: true },
)

watch(
  () => props.name,
  () => {
    localName.value = props.name ?? ''
    startName.value = props.name ?? ''
  },
  { immediate: true },
)

useTableCellFocus({
  cell: target,
  isFocused: toRef(props, 'isFocused'),
  isSelected: toRef(props, 'isSelected'),
})

const resolveCollectionRoute = useResolveSubProjectRoute()
const collectionRoute = computed(() =>
  resolveCollectionRoute({
    parentEntityId: props.entityId,
    subProjectId: props.collectionProjectId,
    parentProjectId: props.projectId,
    workspaceId: props.workspaceId,
  }),
)

const displayValue = computed(() => {
  if (startName.value) return startName.value
  if (!localValue.value || localValue.value === '0') return 'Empty collection'
  if (localValue.value === '1') return `${localValue.value} collection item`
  return `${localValue.value} collection items`
})

const onEdit = () => {
  isEditMode.value = true
  localName.value = startName.value
  nextTick(() => {
    if (collectionNameRef.value) {
      collectionNameRef.value.focus() // Set focus to input
    }
  })
}
</script>

<template>
  <div
    ref="target"
    class="relative size-full outline-none"
  >
    <div
      data-table-cell-content
      class="line-clamp-1 flex h-8 basis-0 items-center truncate rounded-corner-4 px-1 py-2 focus-within:box-border"
      :class="{
        'bg-surface-primary shadow-md': isEditMode,
        'cursor-text': !isEditMode && isManualCollectionEnabled,
      }"
      @click="onEdit"
    >
      <input
        v-if="isEditMode && isManualCollectionEnabled"
        ref="collectionNameRef"
        class="w-full bg-background-transparent px-2 outline-none"
        placeholder="Enter custom collection name"
        :value="startName"
        @focusout="onRenameSave"
        @change="onRenameSave"
        @input="localName = ($event.target as HTMLInputElement).value"
        @blur="onRenameSave"
      />
      <RouterLink
        v-else
        title="Open collection"
        :to="collectionRoute"
        aria-label="Open collection"
        @click.stop
      >
        <div
          v-if="localValue || tool === 'manual'"
          class="flex h-5 items-center gap-1 rounded-corner-6 px-1 hover:bg-background-blue-subtle"
        >
          <IconSprite
            class="text-icon-primary"
            icon="collection-fill"
            size="xs"
          />
          <p
            class="border border-background-transparent border-b-icon-gray-subtle text-sm-12px-default text-icon-primary hover:border-b-background-transparent"
          >
            {{ displayValue }}
          </p>
        </div>
      </RouterLink>
    </div>
  </div>
</template>
