import { setFieldValue, type UpdateEntityFieldValue } from '@/backend/setFieldValue'
import { PropertyType } from '@/backend/types'
import { useEntity } from '@/modules/Project/useEntity'
import {
  serializeFields,
  useProject,
  type Field,
  type Property,
} from '@/modules/Project/useProject'

/**
 * For a given field, returns the object that should be
 * sent to the backend when saving the field's manual value.
 */
const getBackendValue = (field: Field): UpdateEntityFieldValue => {
  if (field.manualValue === null) {
    return null
  }

  switch (field.type) {
    case PropertyType.single_select:
    case PropertyType.multi_select:
    case PropertyType.user_select:
    case PropertyType.file_collection:
    case PropertyType.collection: {
      return {
        options: typeof field.manualValue === 'string' ? [field.manualValue] : field.manualValue,
      }
    }
    case PropertyType.file:
    case PropertyType.pdf: {
      return { file_url: field.manualValue }
    }
    case PropertyType.json: {
      return { json: field.manualValue }
    }
    case PropertyType.url: {
      return { url: field.manualValue }
    }
    case PropertyType.text: {
      return { text: field.manualValue }
    }
  }
}

export type SaveValueParams = {
  workspaceId: string
  projectId: string
  entityId: string
  fields: Record<Property['id'], { field: Field; newValue: string | string[] | null }>
}

/**
 * Composable function that returns a function that will save a new field value
 * on the backend. The function will optimistically update the field's value in
 * the store, and revert the change if the backend request fails.
 */
export const useSetFieldValue = () => {
  const entityStore = useEntity()
  const projectStore = useProject()

  const saveValue = async ({ fields, projectId, workspaceId, entityId }: SaveValueParams) => {
    const oldFields = Object.values(fields)
      .map(({ field }) => field)
      .map((f) => ({ ...f }))

    const newFields = { ...fields }

    // Optimistically update each field in the store
    Object.values(newFields).forEach(({ field: newField, newValue }) => {
      newField.manualValue = newValue
      if (newField.type === PropertyType.file && newValue === null) {
        newField.manualFilename = null
      }

      entityStore.updateField(newField)
      projectStore.updateField(newField)
    })

    // Build the new propertyId=>value mapping to send to the backend
    const newFieldValues = Object.fromEntries(
      Object.entries(newFields).map(([id, { field }]) => [id, getBackendValue(field)]),
    )

    const result = await setFieldValue({
      workspaceId,
      projectId,
      entityId,
      fields: newFieldValues,
    })

    if (result.ok) {
      /**
       * Although we sent an optimistic update, the optimistic field might not contain
       * all the information that the backend returned. Now that we have all the updated
       * information, we update the field in the store again.
       */
      Array.from(serializeFields(result.data.fields).values()).forEach((f) => {
        projectStore.updateField(f)
        entityStore.updateField(f)
      })
    } else {
      oldFields.forEach((oldField) => {
        entityStore.updateField(oldField)
        projectStore.updateField(oldField)
      })
    }

    return result.ok
  }

  return saveValue
}
