
/* GENERATED FILE - DO NOT EDIT */

/**
 * Expand these by looking at either selection.json, style.css or agidb.svg for
 * icons you might need and add them as keys/values into this object.
 *
 * If those files do not contain the icon you need, but it is on figma, then it
 * needs to be added to the font using instructions in adding-icons.md
 */
export const IconName = {
  "ai-sparkles-fill": "ai-sparkles-fill",
  "ai-sparkles": "ai-sparkles",
  "annotators-fill": "annotators-fill",
  "annotators": "annotators",
  "arrow-bottom-circle-fill": "arrow-bottom-circle-fill",
  "arrow-bottom-circle": "arrow-bottom-circle",
  "arrow-bottom-fill": "arrow-bottom-fill",
  "arrow-bottom": "arrow-bottom",
  "arrow-left-fill": "arrow-left-fill",
  "arrow-left": "arrow-left",
  "arrow-right-fill": "arrow-right-fill",
  "arrow-right": "arrow-right",
  "arrow-top-fill": "arrow-top-fill",
  "arrow-top-right": "arrow-top-right",
  "arrow-top": "arrow-top",
  "ask-agi-2-fill": "ask-agi-2-fill",
  "ask-agi-2": "ask-agi-2",
  "ask-agi-fill": "ask-agi-fill",
  "ask-agi": "ask-agi",
  "ask-go": "ask-go",
  "attachment-fill": "attachment-fill",
  "attachment": "attachment",
  "automation-fill": "automation-fill",
  "automation": "automation",
  "benchmark-fill": "benchmark-fill",
  "benchmark": "benchmark",
  "billing-fill": "billing-fill",
  "billing": "billing",
  "bing-logo": "bing-logo",
  "blank": "blank",
  "building-fill": "building-fill",
  "building": "building",
  "calendar-fill": "calendar-fill",
  "calendar": "calendar",
  "chat-bubble-fill": "chat-bubble-fill",
  "chat-bubble": "chat-bubble",
  "check-circle-fill": "check-circle-fill",
  "check-circle": "check-circle",
  "check-fill": "check-fill",
  "check": "check",
  "chevron-bottom-fill": "chevron-bottom-fill",
  "chevron-bottom": "chevron-bottom",
  "chevron-left-fill": "chevron-left-fill",
  "chevron-left": "chevron-left",
  "chevron-right-fill": "chevron-right-fill",
  "chevron-right": "chevron-right",
  "chevron-select-fill": "chevron-select-fill",
  "chevron-select": "chevron-select",
  "chevron-top-fill": "chevron-top-fill",
  "chevron-top": "chevron-top",
  "circle-plus-fill": "circle-plus-fill",
  "circle-plus": "circle-plus",
  "clock-fill": "clock-fill",
  "clock": "clock",
  "close-fill": "close-fill",
  "close": "close",
  "collection-fill": "collection-fill",
  "collection": "collection",
  "company-anthropic": "company-anthropic",
  "company-azure": "company-azure",
  "company-google": "company-google",
  "company-openai": "company-openai",
  "company-v7": "company-v7",
  "company-vertex": "company-vertex",
  "company-zapier": "company-zapier",
  "copy-fill": "copy-fill",
  "copy": "copy",
  "edit-fill": "edit-fill",
  "edit": "edit",
  "empty-fill": "empty-fill",
  "empty": "empty",
  "enter-fill": "enter-fill",
  "enter": "enter",
  "entity-view-fill": "entity-view-fill",
  "entity-view": "entity-view",
  "excel-logo": "excel-logo",
  "expand-fill": "expand-fill",
  "expand": "expand",
  "file-fill": "file-fill",
  "file": "file",
  "filter-fill": "filter-fill",
  "filter-sort-fill": "filter-sort-fill",
  "filter-sort": "filter-sort",
  "filter": "filter",
  "folder-fill": "folder-fill",
  "folder": "folder",
  "handle-fill": "handle-fill",
  "handle": "handle",
  "help": "help",
  "hide-fill": "hide-fill",
  "hide": "hide",
  "human-task-fill": "human-task-fill",
  "human-task": "human-task",
  "idea-fill": "idea-fill",
  "idea": "idea",
  "info-fill": "info-fill",
  "info": "info",
  "integrations-fill": "integrations-fill",
  "integrations": "integrations",
  "json-fill": "json-fill",
  "json": "json",
  "layout-fill": "layout-fill",
  "layout": "layout",
  "light-mode": "light-mode",
  "link-fill": "link-fill",
  "link": "link",
  "lock-fill": "lock-fill",
  "lock": "lock",
  "minus-fill": "minus-fill",
  "minus": "minus",
  "more-dots-circle-fill": "more-dots-circle-fill",
  "more-dots-circle": "more-dots-circle",
  "more-dots-fill": "more-dots-fill",
  "more-dots": "more-dots",
  "multi-select-fill": "multi-select-fill",
  "multi-select": "multi-select",
  "mute-fill": "mute-fill",
  "mute": "mute",
  "notification-fill": "notification-fill",
  "notification": "notification",
  "number-fill": "number-fill",
  "number": "number",
  "ocr-fill": "ocr-fill",
  "ocr": "ocr",
  "pause-fill": "pause-fill",
  "pause": "pause",
  "picture-fill": "picture-fill",
  "picture": "picture",
  "pin-fill": "pin-fill",
  "pin": "pin",
  "play-fill": "play-fill",
  "play": "play",
  "playback-fill": "playback-fill",
  "playback": "playback",
  "plus-fill": "plus-fill",
  "plus": "plus",
  "process-fill": "process-fill",
  "process": "process",
  "python-language": "python-language",
  "rating-fill": "rating-fill",
  "rating": "rating",
  "refresh": "refresh",
  "remove-picture-fill": "remove-picture-fill",
  "remove-picture": "remove-picture",
  "search-fill": "search-fill",
  "search": "search",
  "settings-fill": "settings-fill",
  "settings": "settings",
  "show-fill": "show-fill",
  "show": "show",
  "sidebar-fill": "sidebar-fill",
  "sidebar": "sidebar",
  "single-select-fill": "single-select-fill",
  "single-select": "single-select",
  "source-fill": "source-fill",
  "source": "source",
  "table-fill": "table-fill",
  "table": "table",
  "task-fill": "task-fill",
  "task": "task",
  "templates-fill": "templates-fill",
  "templates": "templates",
  "text-fill": "text-fill",
  "text": "text",
  "thumbs-down-fill": "thumbs-down-fill",
  "thumbs-down": "thumbs-down",
  "thumbs-up-fill": "thumbs-up-fill",
  "thumbs-up": "thumbs-up",
  "tool-fill": "tool-fill",
  "tool": "tool",
  "trash-fill": "trash-fill",
  "trash": "trash",
  "unlink-fill": "unlink-fill",
  "unlink": "unlink",
  "unlock-fill": "unlock-fill",
  "unlock": "unlock",
  "upload-fill": "upload-fill",
  "upload": "upload",
  "user-2-fill": "user-2-fill",
  "user-2": "user-2",
  "user-fill": "user-fill",
  "user-plus-fill": "user-plus-fill",
  "user-plus": "user-plus",
  "user": "user",
  "video-fill": "video-fill",
  "video": "video",
  "volume-high-fill": "volume-high-fill",
  "volume-high": "volume-high",
  "volume-low-fill": "volume-low-fill",
  "volume-low": "volume-low",
  "volume-medium-fill": "volume-medium-fill",
  "volume-medium": "volume-medium",
  "warning-fill": "warning-fill",
  "warning": "warning",
  "workflow-fill": "workflow-fill",
  "workflow": "workflow"
} as const

export type IconName = (typeof IconName)[keyof typeof IconName]
