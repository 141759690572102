import { getProject } from '@/backend/getProject'
import { removeProperty } from '@/backend/removeProperty'
import { updateProperty } from '@/backend/updateProperty'
import { updateView } from '@/backend/updateView'
import { useBilling } from '@/modules/Billing/useBilling'
import { useSubProject } from './useSubProject'

import { PropertyTool, type UpdatePropertyInput } from '@/backend/types'
import { toast } from '@/shared/toast'
import { isEmptyObject } from '@/shared/utils/typeGuards'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { ref, watch } from 'vue'
import { useLimitedAction } from '../Billing/useLimitedAction'
import { useLibraryStore } from '../Library/libraryStore'
import { useWorkspaces } from '../Workspaces/useWorkspaces'
import { serializeProperty, useProject, type Property } from './useProject'
import { useProperty } from './useProperty'

export const usePropertySidebarIntegration = () => {
  const projectStore = useProject()
  const propertyStore = useProperty()
  const workspacesStore = useWorkspaces()
  const libraryStore = useLibraryStore()

  const subProjectStore = useSubProject()

  const isPropertyBusy = ref(false)

  const deselectProperty = () => {
    projectStore.setSelectedProperty(null)
    propertyStore.reset()
  }

  const { captureAnalyticsEvent } = useAnalytics()
  const saveProperty = async (
    {
      description,
      inputs,
      name,
      tool,
      type,
      isGrounded,
      config,
    }: Pick<Property, 'name' | 'type' | 'tool' | 'isGrounded' | 'inputs' | 'description'> & {
      config?: Parameters<typeof updateProperty>[3]['config']
    },
    savedFrom: 'menu' | 'sidebar',
  ) => {
    const property = propertyStore.property
    const workspace = workspacesStore.currentWorkspace
    const projectId = projectStore.projectId
    if (!property || !projectId || !workspace) {
      return false
    }

    isPropertyBusy.value = true
    const result = await updateProperty(workspace.id, projectId, property.id, {
      name,
      type,
      tool,
      isGrounded,
      description: description?.trim() === '' ? null : description,
      inputs:
        tool === PropertyTool.manual
          ? []
          : inputs.map<UpdatePropertyInput>(({ propertyId, entityId, filters }) => ({
              entity_id: entityId || undefined,
              property_id: propertyId,
              entities_filter: isEmptyObject(filters) ? undefined : filters || undefined,
            })),
      config: config
        ? {
            defaultOption: config.defaultOption,
            maxSelected: config.maxSelected,
            removeOptions: config.removeOptions,
            // skip empty value upsertOptions, thus the response will overwrite the existing options and they will be removed from the UI too, otherwise we fall into the trap of empty options and validation needs to be added
            upsertOptions: config.upsertOptions?.filter(
              (o) => o.value.trim() !== '' && o.new_value?.trim() !== '',
            ),
            upsertCollectionProperties: config.upsertCollectionProperties,
            removeCollectionProperties: config.removeCollectionProperties,
          }
        : undefined,
    })

    if (result.ok) {
      const deletedLibraryInputs = property.inputs.filter(
        ({ propertyId, entityId }) =>
          entityId &&
          !inputs.some((input) => input.propertyId === propertyId && input.entityId === entityId),
      )
      deletedLibraryInputs.forEach(({ entityId }) => {
        if (!entityId) {
          return
        }
        libraryStore.deleteInputReference({
          itemId: entityId,
          projectId,
          propertyId: property.id,
        })
      })

      inputs
        .filter(
          ({ propertyId, entityId }) =>
            entityId &&
            !property.inputs.some(
              (input) => input.propertyId === propertyId && input.entityId === entityId,
            ),
        )
        .forEach(({ entityId }) => {
          if (!entityId) {
            return
          }

          libraryStore.addInputReference({
            itemId: entityId,
            projectId,
            propertyId: property.id,
          })
        })

      projectStore.upsertProperty(serializeProperty(result.data))
      propertyStore.reset()

      const hasUpdatedSubProjectProperties =
        (config?.upsertCollectionProperties?.length ?? 0) +
          (config?.removeCollectionProperties?.length ?? 0) >
        0
      if (
        hasUpdatedSubProjectProperties &&
        'config' in property &&
        property.config &&
        'subprojectConfig' in property.config &&
        workspacesStore.currentWorkspace
      ) {
        // we need to refetch the subproject properties to update the UI, or stale properties appear in MentionableTextInput
        // see GO-2248 for more
        const result = await getProject(
          workspacesStore.currentWorkspace.id,
          property.config.subprojectConfig.child_project_id,
        )
        if (result.ok) {
          if (result.data.parent_property === null) {
            throw Error('Subproject must have a parent property')
          }
          subProjectStore.setProject(property.config.subprojectConfig.child_project_id, {
            id: result.data.id,
            name: result.data.name,
            workspaceId: result.data.workspace_id,
            properties: result.data.properties.map(serializeProperty),
            parentPropertyId: result.data.parent_property.id,
          })
        }
      }

      captureAnalyticsEvent(ANALYTICS_EVENT.PROPERTY_UPDATED, {
        workspaceId: workspace.id,
        projectId,
        propertyId: property.id,
        savedFrom,
      })

      if (tool !== PropertyTool.manual) {
        captureAnalyticsEvent(ANALYTICS_EVENT.AI_PROPERTY_CREATED)
      }
      if (description && inputs.length > 0) {
        captureAnalyticsEvent(ANALYTICS_EVENT.AI_PROMPT_AND_INPUT_SAVED)
      }

      const advancedTypes: Array<typeof type> = [
        'url',
        'json',
        'single_select',
        'multi_select',
        'collection',
      ]
      if (advancedTypes.includes(type)) {
        captureAnalyticsEvent(ANALYTICS_EVENT.ADVANCED_PROPERTY_SUCCESS, {
          type,
        })
      }
    } else if (!result.ok) {
      toast.error(result.error.message)
      return false
    }

    isPropertyBusy.value = false
    return true
  }

  const deletePropertyConfirmationOpen = ref(false)

  const billingStore = useBilling()
  const deleteProperty = async () => {
    if (!projectStore.projectId || !projectStore.selectedPropertyId) {
      return
    }
    isPropertyBusy.value = true
    const propertyId = projectStore.selectedPropertyId
    const workspace = workspacesStore.currentWorkspace
    if (!workspace) {
      return
    }
    const result = await removeProperty(workspace.id, projectStore.projectId, propertyId)
    if (result.ok) {
      if (billingStore.fieldUsage) {
        billingStore.fieldUsage.limitUsage -= projectStore.mainView?.entities?.length ?? 0
      }

      projectStore.setSelectedProperty(propertyId)
      projectStore.removeProperty(propertyId)
    }
    isPropertyBusy.value = false
    deletePropertyConfirmationOpen.value = false
  }

  const reprocessColumnConfirmationOpen = ref(false)

  const { recalculateEntities } = useLimitedAction()
  const reprocessColumn = async () => {
    if (
      !workspacesStore.currentWorkspace ||
      !projectStore.projectId ||
      !projectStore.selectedPropertyId
    ) {
      return
    }

    isPropertyBusy.value = true

    const result = await recalculateEntities(
      workspacesStore.currentWorkspace.id,
      projectStore.projectId,
      [projectStore.selectedPropertyId],
    )

    isPropertyBusy.value = false

    if (result.ok) {
      if (result.data.affected_count === 0) {
        toast.info('No stale fields found')
      } else {
        toast.info(`Recomputing ${result.data.affected_count} fields`)
      }
    }

    reprocessColumnConfirmationOpen.value = false
  }

  const hideProperty = async () => {
    if (projectStore.activeView && workspacesStore.currentWorkspace && projectStore.projectId) {
      await updateView({
        workspaceId: workspacesStore.currentWorkspace.id,
        projectId: projectStore.projectId,
        viewId: projectStore.activeView.id,
        name: projectStore.activeView.view.name,
        propertyIds:
          projectStore.activeView.view.propertyIds?.filter(
            (id) => id !== projectStore.selectedPropertyId,
          ) ?? [],
        propertyLayouts: projectStore.activeView.view.propertyLayouts,
        filters: projectStore.activeView.view.filters,
        propertyOptions: projectStore.activeView.view.propertyOptions ?? [],
        assignablePropertyId: projectStore.activeView.view.assignablePropertyId,
      })
      deselectProperty()
    }
  }

  watch(
    () => projectStore.selectedPropertyId,
    (newValue, oldValue) => {
      if (newValue !== oldValue) propertyStore.reset()
    },
    { immediate: true },
  )

  return {
    deleteProperty,
    deletePropertyConfirmationOpen,
    deselectProperty,
    hideProperty,
    isPropertyBusy,
    reprocessColumn,
    reprocessColumnConfirmationOpen,
    saveProperty,
  }
}
