<script setup lang="ts">
import { fileNameIsPdf } from '@/shared/utils/file'
import { computed } from 'vue'
import GroundingModalImage from './GroundingModalImage.vue'
import { useGroundingStore } from './useGroundingStore'
import GroundingModalPdf from './GroundingModalPdf.vue'

const groundingStore = useGroundingStore()

const activeInputField = computed(() =>
  groundingStore.inputFields.find((field) => field.propertyId === groundingStore.activeInputId),
)

const isPdf = computed(() => fileNameIsPdf(activeInputField.value?.manualValue ?? ''))
</script>

<template>
  <GroundingModalPdf
    v-if="isPdf && activeInputField"
    show-sidebar
    storage-prefix="grounding-modal"
    :file-path="activeInputField.manualValue || activeInputField.toolValue || ''"
    :grounding-info="{
      sources: groundingStore.activeInputSources,
      ocrPages: activeInputField.ocrPages ?? [],
    }"
    v-bind="$attrs"
  />
  <GroundingModalImage
    v-else-if="activeInputField?.manualValue && activeInputField.ocrPages"
    :image-path="activeInputField.manualValue"
    :sources="groundingStore.activeInputSources"
    :ocr-page="activeInputField.ocrPages[0]"
    v-bind="$attrs"
  />
</template>
