import { addProperty } from '@/backend/addProperty'
import { createProject } from '@/backend/createProject'
import { getProject } from '@/backend/getProject'
import { tryUntilItWorks } from '@/shared/utils/try'
import * as sentry from '@sentry/vue'
import { ref } from 'vue'
import { useRefreshUsage } from '../Billing/useRefreshUsage'
import { useCreateEntity } from '../Project/useCreateEntity'
import { useEntityView } from '../Project/useEntityView'
import { serializeProperty, serializeView, useProject } from '../Project/useProject'
import { useProjects } from '../Projects/useProjects'
import { useWorkspaces } from '../Workspaces/useWorkspaces'

export function useCreateWelcomeProject() {
  const workspaceStore = useWorkspaces()
  const projectsStore = useProjects()
  const { refreshUsageNTimes } = useRefreshUsage()
  const { createEntity } = useCreateEntity()
  const { updateItemSize } = useEntityView()
  const welcomeProject = ref<string | null>(null)
  const projectStore = useProject()

  type Status = 'FAIL' | 'SUCCESS' | 'IN_PROGRESS' | 'IDLE'
  const status = ref<Status>('IDLE')

  async function createWelcomeProject(): Promise<boolean> {
    if (status.value !== 'IDLE' || welcomeProject.value) return false

    status.value = 'IN_PROGRESS'
    const workspaceId = workspaceStore.currentWorkspace?.id
    if (!workspaceId) {
      status.value = 'FAIL'
      sentry.captureException(new Error('Tried creating welcome project without a workspace ID'))
      return false
    }

    let project = await createProject(null, workspaceId)
    if (!project.ok) {
      status.value = 'FAIL'
      sentry.captureException(project.error)
      return false
    }

    const property = await tryUntilItWorks(
      async () => {
        if (!project.ok) return
        return await addProperty(workspaceId, project.data.id, {
          type: 'file',
          name: 'File',
          tool: 'manual',
          isGrounded: false,
          description: null,
          inputIds: [],
        })
      },
      { maxTries: 500, isValid: (res) => Boolean(res?.ok), waitBetweenTries: 100 },
    )

    if (!property?.ok) {
      status.value = 'FAIL'
      sentry.captureException(property?.error)
      return false
    }

    // Get project again since it has updated views and what not
    project = await getProject(workspaceId, project.data.id)
    if (!project.ok) {
      status.value = 'FAIL'
      sentry.captureException(project.error)
      return false
    }

    projectsStore.addProject({
      id: project.data.id,
      name: project.data.name,
      workspaceId: workspaceId,
      propertyCount: 1,
      coverImageUrls: project.data.cover_image_urls,
    })

    projectStore.setProjectId(project.data.id)
    projectStore.setProperties([serializeProperty(property.data)])
    projectStore.projectLoaded = true
    projectStore.setActiveViewId(project.data.views[0].id)
    projectStore.setViews(project.data.views[0].id ?? null, project.data.views.map(serializeView))

    await updateItemSize({
      propertyId: property.data.id,
      workspaceId,
      projectId: project.data.id,
      layout: {
        x: 0,
        y: 0,
        width: 4,
        height: 20,
      },
    })

    const entityPromises = [...new Array(3)].map(async () => {
      return await createEntity()
    })

    const results = await Promise.all(entityPromises)
    if (results.some((r) => r === null)) {
      status.value = 'FAIL'
      sentry.captureException(new Error('Failed to create entities for welcome project'))
      return false
    }

    refreshUsageNTimes(3)

    welcomeProject.value = project.data.id
    status.value = 'SUCCESS'

    return true
  }

  function reset() {
    status.value = 'IDLE'
    welcomeProject.value = null
  }

  return { status, welcomeProject, createWelcomeProject, reset }
}
