<script setup lang="ts">
import { autoUpdate, useFloating, size, offset, flip } from '@floating-ui/vue'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { isEqual } from '@/shared/utils'
import { SELECTED_CELL_Z_INDEX, PINNED_CELL_Z_INDEX } from './useTableZIndices'
import { useElementBounding } from '@vueuse/core'
import { injectPinnedProp } from './usePinnedColumn'

const props = defineProps<{
  isFocused: boolean
  value?: string[]
  isMulti?: boolean
  isUser?: boolean
  valueToColorMap: Partial<Record<string, string | null | undefined>>
  options: { id: string; data: { label: string } }[]
  isModelOutput?: boolean
  hasReasoning?: boolean
  hasSelectedRange?: boolean
}>()

const emit = defineEmits<{
  (e: 'click', event: MouseEvent): void
  (e: 'submit', value: string[]): void
}>()

const target = ref()
const floatingMenu = ref<HTMLDivElement>()
const floatingValue = ref<HTMLDivElement>()
const listMenuRef = ref<typeof ListMenu>()

const { height, left, right } = useElementBounding(target)
const floatingValueStyles = computed(() => {
  return {
    minWidth: right.value - left.value + 'px',
    maxWidth: '610px',
    height: 'auto',
    maxHeight: '400px',
    minHeight: height.value + 'px',
    overflow: 'auto',
  }
})

const localValue = ref(props.value ? [...props.value] : [])

watch(
  () => props.value,
  () => {
    localValue.value = props.value ?? []
  },
)

const toggleValue = (option: string) => {
  if (!(localValue.value instanceof Array)) {
    return
  }

  localValue.value = localValue.value.includes(option)
    ? localValue.value.filter((o) => o !== option)
    : localValue.value.concat(option)
}

const availableOptions = computed(() => {
  if (props.isMulti) {
    return props.options.filter((o) => !localValue.value.includes(o.data.label))
  }
  return props.options
})

const { floatingStyles: floatingMenuStyles } = useFloating(
  floatingValue,
  // temporary fix for an issue with floatingUI, where a ref being a component, not an element
  // causes an error in headless mode (CI, for example)
  floatingMenu,
  {
    placement: 'bottom-start',
    middleware: [
      size({
        apply({ availableHeight, elements, rects }) {
          elements.floating.style.maxHeight = Math.min(availableHeight - 16, 500) + 'px'
          elements.floating.style.width = rects.reference.width + 8 + 'px'
        },
      }),
      offset({ mainAxis: 4, alignmentAxis: -4 }),
      flip(),
    ],
    whileElementsMounted: autoUpdate,
  },
)

const toggleItemInputAndSingleSubmit = (label?: string) => {
  const value = label?.trim()
  if (!value) return

  if (props.isMulti) {
    toggleValue(value)
  } else {
    localValue.value = localValue.value[0] === label ? [] : [value]
    safeSubmitLocalValue()
  }
}

const safeSubmitLocalValue = () => {
  if (isEqual(props.value, localValue.value)) return

  if (props.isUser) {
    const [value] = localValue.value
    const option = props.options.find((o) => o.data.label === value)
    emit('submit', [option?.id ?? value])
    return
  }

  emit('submit', localValue.value)
}

onBeforeUnmount(() => {
  if (props.isMulti) safeSubmitLocalValue()
})

const selectIcon = (item: { id: string; data: { label: string } }) => {
  if (props.isMulti) return

  const valueToCheck = props.isUser ? item.id : item.data.label
  return localValue.value.includes(valueToCheck) ? 'check' : undefined
}

const isPinned = injectPinnedProp()
</script>

<template>
  <div
    ref="target"
    class="size-full"
  >
    <div
      ref="floatingValue"
      aria-label="Focused select values"
      class="relative flex min-h-8 w-max cursor-pointer select-none flex-wrap items-start gap-1 overflow-auto rounded-corner-4 p-1 pt-1.5 outline outline-2 outline-border-focused"
      :class="[
        isModelOutput && !isFocused
          ? 'before:absolute before:inset-0 before:bg-surface-primary after:absolute after:inset-0 after:bg-background-stages-model-subtle'
          : 'bg-surface-popover',
        hasSelectedRange &&
          'before:absolute before:inset-0 before:size-full before:!bg-background-selected',
      ]"
      :style="{
        ...floatingValueStyles,
        zIndex: isPinned ? PINNED_CELL_Z_INDEX : SELECTED_CELL_Z_INDEX,
      }"
      @click="$emit('click', $event)"
    >
      <div
        v-if="localValue.length === 0 && hasReasoning"
        class="relative z-20 h-4 max-w-full truncate whitespace-nowrap pl-2 text-text-subtlest"
      >
        No match found
      </div>
      <BadgeItem
        v-for="(v, idx) in localValue"
        :key="idx"
        :leading-icon="isUser ? 'user-fill' : undefined"
        :label="isUser ? options.find((o) => o.id === v)!.data.label : v"
        size="sm"
        :variant="isUser ? 'blue' : 'warning'"
        class="relative z-10 max-w-full truncate whitespace-nowrap"
        :rainbow-color="valueToColorMap[v]"
        :trailing-icon="isFocused && isMulti ? 'close' : undefined"
        @trailing-icon-click="toggleItemInputAndSingleSubmit(v)"
      />
    </div>
    <div
      v-if="isFocused"
      ref="floatingMenu"
      class="box-border flex h-auto max-h-[500px] min-h-[70px] shrink grow-0 select-none flex-col"
      :style="floatingMenuStyles"
    >
      <ListMenu
        ref="listMenuRef"
        :items="availableOptions"
        :all-items="options"
        search-by-field="label"
        no-results-text="No result"
        :initial-active-item-predicate="
          isMulti ? () => true : (o) => localValue.includes(o.data.label)
        "
        @delete="toggleItemInputAndSingleSubmit(localValue.at(-1))"
        @select="({ label }) => toggleItemInputAndSingleSubmit(label)"
      >
        <template #item="{ key, item, active, setActiveItem, focus }">
          <ListMenuItem
            :active="active"
            auto-scroll-to-active
            default-hover-disabled
            :icon="selectIcon(item)"
            @select="toggleItemInputAndSingleSubmit(item.data.label), focus()"
            @mousemove="setActiveItem(key)"
          >
            <BadgeItem
              :leading-icon="isUser ? 'user-fill' : undefined"
              class="max-w-full"
              :label="item.data.label"
              :rainbow-color="valueToColorMap[isUser ? item.id : item.data.label]"
              size="sm"
              :variant="isUser ? 'blue' : 'warning'"
            />
            <template
              v-if="
                !isMulti && !localValue.includes(item.data.label) && !localValue.includes(item.id)
              "
              #prefix
            >
              <div class="size-4"></div>
            </template>
          </ListMenuItem>
        </template>
      </ListMenu>
    </div>
  </div>
</template>
