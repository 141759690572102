import type { EditorState } from 'prosemirror-state'
import type { EditorView } from 'prosemirror-view'
import type { Ref } from 'vue'
import { reactive } from 'vue'
import { markdownSchema } from './markdown'

const markKeys = Object.keys(markdownSchema.marks)

/**
 * Returns reactive state for each mark in the current selection, and
 * a function used to update that state.
 */
export const useMarkState = (view: Ref<EditorView | undefined>) => {
  /**
   * Reactive object that stores the state of each mark in the current selection.
   */
  const markState = reactive(Object.fromEntries(markKeys.map((k) => [k, false])))

  const resetMarkState = () => {
    markKeys.forEach((key) => {
      markState[key] = false
    })
  }

  /**
   * Populates each key in markState with a boolean indicating whether the mark is
   * active in the current selection.
   */
  const setMarkState = (state: EditorState) => {
    resetMarkState()
    if (!view.value) return false
    view.value.state.doc.nodesBetween(state.selection.from, state.selection.to, (node) => {
      markKeys.forEach((key) => {
        const markType = markdownSchema.marks[key]
        if (node.marks.some((nodeMark) => nodeMark.type === markType)) {
          markState[key] = true
        }
      })
    })
  }

  return {
    markState,
    setMarkState,
  }
}
