<script setup lang="ts">
import { computed, watch } from 'vue'
import { useStorage } from '@vueuse/core'

import { listFilteredEntities } from '@/backend/listFilteredEntities'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import IconSprite from '@/uiKit/IconSprite.vue'

import { useFilters } from './useFilters'
import { serializeEntity, useProject } from './useProject'
import FilterBarAddFilter from './Filters/FilterBarAddFilter.vue'
import IconButton from '@/uiKit/IconButton.vue'
import { useRoute } from 'vue-router'
import type { GroupFilter } from './Filters/types'

const workspacesStore = useWorkspaces()
const projectStore = useProject()
const filtersStore = useFilters()
const localFilter = useStorage<Record<string, GroupFilter>>('localFilter', {})

// If local filter is set to a value !== undefined, set the current filter to that value
// Also, if the active view changes, set the current filter to the local filter value for that view
watch(
  () => [localFilter.value, projectStore.activeView] as const,
  ([filter, view]) => {
    if (!view) return
    filtersStore.setCurrentFilter(filter[view.id])
  },
  { immediate: true },
)

const route = useRoute()
const parentEntityId = computed(() => route.query.parentEntityId)

// When the current filter changes in the store, load the filtered entities and replace the entities in the dedicated store
watch(
  () => filtersStore.currentFilter,
  async (newFilter) => {
    if (!workspacesStore.currentWorkspace) return
    if (!projectStore.activeView) return
    if (!projectStore.projectId) return
    if (!newFilter) return

    const viewFilter: GroupFilter =
      newFilter.filters.length > 0
        ? {
            conjunction: 'and',
            filters: [
              newFilter,
              {
                matcher: { name: 'any_of', values: [projectStore.activeView.id] },
                subject: 'active_view_id',
              },
            ],
          }
        : {
            conjunction: 'and',
            filters: [
              {
                matcher: { name: 'any_of', values: [projectStore.activeView.id] },
                subject: 'active_view_id',
              },
            ],
          }

    filtersStore.viewFilters = newFilter.filters.length > 0

    if (parentEntityId.value && typeof parentEntityId.value === 'string') {
      viewFilter.filters.push({
        matcher: { name: 'any_of', values: [parentEntityId.value] },
        subject: 'parent_entity_id',
      })
    }

    const entitiesResult = await listFilteredEntities({
      workspaceId: workspacesStore.currentWorkspace.id,
      projectId: projectStore.projectId,
      filter: viewFilter,
    })

    if (entitiesResult.ok === false) {
      projectStore.setEntitiesStale(true)
      return
    }

    const listedEntities = entitiesResult.data.data.map(serializeEntity)
    const totalCountFromServer = entitiesResult.data.metadata.total_count
    projectStore.spliceEntities(listedEntities, totalCountFromServer, 0, projectStore.activeView.id)
  },
  { deep: true, immediate: true },
)
</script>

<template>
  <button
    v-if="filtersStore.currentFilter && filtersStore.currentFilter.filters.length > 0"
    aria-label="Manage filters"
    class="flex h-7 cursor-pointer select-none items-center gap-1 rounded-corner-8 bg-background-gray-subtlest px-2 py-1 hover:bg-background-gray-subtlest-hovered active:bg-background-gray-subtlest-pressed"
    @click="filtersStore.viewFilters = !filtersStore.viewFilters"
  >
    <IconSprite
      class="text-icon-subtle"
      size="xs"
      icon="filter"
    />
    <BadgeItem
      v-if="filtersStore.currentFilter && filtersStore.currentFilter.filters.length > 0"
      :label="`${filtersStore.currentFilter.filters.length}`"
      size="xs"
      variant="blue"
    />
  </button>
  <FilterBarAddFilter v-else>
    <template #trigger="{ isOpen, onClick }">
      <IconButton
        size="lg"
        aria-label="Add a filter"
        variant="transparent"
        icon="filter"
        :active="isOpen"
        @click="onClick"
      >
      </IconButton>
    </template>
  </FilterBarAddFilter>
</template>
