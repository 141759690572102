<template>
  <div
    class="group flex min-h-0 cursor-pointer flex-col items-stretch gap-0 rounded-corner-10 bg-surface-tertiary shadow-lg outline outline-1 outline-border-subtle"
  >
    <div
      v-if="$slots.header"
      data-test="list-menu-header"
      class="flex h-[27px] items-center px-3 text-sm-12px-default text-text-subtlest"
    >
      <slot name="header" />
    </div>
    <div class="rounded-corner-10 bg-surface-popover">
      <slot />
    </div>
  </div>
</template>
