<script lang="ts">
export type CollectionOrFileCollection = Property<'collection' | 'file_collection'>

/**
 * HTML ID attribute for the container element of this component. Is
 * used to teleport popup menus into this element.
 */
export const FILTER_MENU_ID = 'model-input-menu-filters'

/**
 * Subset of filters that are supported by this component and its
 * descendants. Defining this type here means that we can guard
 * against unsupported filters at the top level, instead of guarding
 * repeatedly in each child component.
 */
export type InputFilter = TextValueFilter | SelectOptionValueFilter
</script>

<script setup lang="ts">
import ListMenuContainer from '@/uiKit/ListMenuContainer.vue'
import type { Property, PropertyInput } from './useProject'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import DividerLine from '@/uiKit/DividerLine.vue'
import { computed, ref, watch } from 'vue'
import {
  type Filter,
  type FilterableProperty,
  type SelectOptionValueFilter,
  type TextValueFilter,
} from './Filters/types'
import { isEmptyObject } from '@/shared/utils/typeGuards'
import ModelInputMenuFiltersFilterItem from './ModelInputMenuFiltersFilterItem.vue'
import ModelInputMenuFiltersFilterDraft from './ModelInputMenuFiltersFilterDraft.vue'
import IconButton from '@/uiKit/IconButton.vue'
import SegmentedControl, { type SegmentedControlItem } from '@/uiKit/SegmentedControl.vue'
import type { Conjunction } from './useFilters'

const props = defineProps<{
  property: CollectionOrFileCollection
  filters?: PropertyInput['filters']
  subprojectProperties: FilterableProperty[]
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'update', filters: PropertyInput['filters'] | null): void
}>()

const isInputFilter = (filter: Filter): filter is InputFilter => {
  if ('conjunction' in filter) {
    return false
  }

  return filter.subject === 'field_text_value' || filter.subject === 'field_select_option_value'
}

const getInitialFilters = (inputFilters: PropertyInput['filters']): InputFilter[] => {
  if (!inputFilters) return []

  if ('conjunction' in inputFilters) {
    return inputFilters.filters.filter(isInputFilter)
  } else if (isEmptyObject(inputFilters)) {
    return []
  } else {
    return isInputFilter(inputFilters) ? [inputFilters] : []
  }
}

type DraftFilter = {
  matcher: undefined
}
const localFilters = ref<(InputFilter | DraftFilter)[]>(getInitialFilters(props.filters))

const addDraftFilter = () => {
  localFilters.value.push({
    matcher: undefined,
  })
}

const onUpdateFilter = (filter: InputFilter, index: number) => {
  localFilters.value = localFilters.value.with(index, filter)
}

const getInitialConjunction = (inputFilters: PropertyInput['filters']): Conjunction => {
  if (!inputFilters) return 'and'

  if ('conjunction' in inputFilters) {
    return inputFilters.conjunction
  } else {
    return 'and'
  }
}
const conjunction = ref<Conjunction>(getInitialConjunction(props.filters))
const conjunctionOptions: SegmentedControlItem<Conjunction>[] = [
  { value: 'and', label: 'And' },
  { value: 'or', label: 'Or' },
]

const inputFilter = computed<PropertyInput['filters'] | null>(() => {
  const validFilters = localFilters.value.filter((filter) => !!filter.matcher)

  if (validFilters.length === 0) {
    return null
  }

  return {
    conjunction: conjunction.value,
    filters: validFilters,
  }
})

watch(inputFilter, (newFilters) => {
  emit('update', newFilters)
})
</script>

<template>
  <ListMenuContainer
    :id="FILTER_MENU_ID"
    role="menu"
    :aria-label="`${property.name} input filters`"
    class="min-w-[400px] !cursor-default"
  >
    <div class="flex h-8 items-center gap-1 px-2 py-0.5">
      <DarwinButton
        variant="transparent"
        class="!pl-0.5"
        size="sm"
        aria-label="Close filter menu"
        @click="$emit('close')"
      >
        <template #leading-icon>
          <IconSprite icon="chevron-left" />
        </template>
        {{ property.name }}
      </DarwinButton>
    </div>
    <DividerLine color="subtle" />
    <ul
      class="grid grid-cols-[auto,1fr,1fr,1fr,auto] gap-y-1"
      :class="localFilters.length > 0 ? 'p-1' : 'p-0.5'"
    >
      <li
        v-if="localFilters.length === 0"
        class="flex h-7 items-center px-2.5 text-sm-12px-default text-text-disabled"
      >
        No filter set
      </li>
      <li
        v-for="(filter, index) in localFilters"
        :key="index"
        class="contents"
      >
        <div class="mr-1 flex h-7 min-w-16 items-center">
          <div
            v-if="index === 0"
            class="px-2 text-sm-12px-default text-text"
          >
            Where
          </div>
          <SegmentedControl
            v-else-if="index === 1"
            :items="conjunctionOptions"
            :value="conjunction"
            name="And/Or"
            @change="conjunction = $event"
          />
          <div
            v-else
            class="w-full px-2 text-sm-12px-default text-text"
            :class="conjunction === 'or' && 'pr-2.5 text-right'"
          >
            {{ conjunction === 'and' ? 'And' : 'Or' }}
          </div>
        </div>
        <ModelInputMenuFiltersFilterItem
          v-if="filter.matcher"
          :properties="subprojectProperties"
          :filter="filter"
          @update="onUpdateFilter($event, index)"
        />
        <ModelInputMenuFiltersFilterDraft
          v-else
          :properties="subprojectProperties"
          @create="onUpdateFilter($event, index)"
        />
        <IconButton
          class="ml-1"
          icon="close"
          size="lg"
          variant="transparent"
          :aria-label="`Delete filter ${index + 1}`"
          @click="localFilters.splice(index, 1)"
        />
      </li>
    </ul>
    <DividerLine color="subtle" />
    <div class="px-2 py-0.5">
      <DarwinButton
        variant="transparent"
        size="sm"
        class="!pl-0.5"
        @click="addDraftFilter"
      >
        <template #leading-icon>
          <IconSprite icon="plus" />
        </template>
        Add a filter
      </DarwinButton>
    </div>
  </ListMenuContainer>
</template>
