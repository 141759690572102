import type { EmptyObject } from '@/shared/types'
import { isEmptyObject } from '@/shared/utils/typeGuards'
import type { Field } from '../useProject'
import type { Filter } from './types'

export const getCaseInsensitiveValue = (v: Field['manualValue']): Field['manualValue'] => {
  if (v === null) {
    return v
  }

  if (Array.isArray(v)) {
    return v.map((s) => s.toLowerCase())
  }

  return v.toLowerCase()
}

/** Returns the total number of filters contained within a recursive filter object */
export const countFilters = (filter: Filter | EmptyObject | undefined): number => {
  if (!filter || isEmptyObject(filter)) {
    return 0
  }

  // If the filter has a conjunction, then it's a group filter so we must count
  // the number of filters in all of its children.
  if ('conjunction' in filter) {
    return filter.filters.reduce((acc, f) => acc + countFilters(f), 0)
  }

  // If we're here, the filter is a SimpleFilter, so has one condition.
  return 1
}
