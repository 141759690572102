import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { computed, watchEffect, type ComputedRef } from 'vue'
import { PropertyType } from '../../backend/types'
import { useModelProviders } from '../WorkspaceSettings/modelProvidersStore'
import { FIELD_TYPES_LABELS } from '../WorkspaceSettings/propertyConfig'
import { useProject } from './useProject'
import { useProperty } from './useProperty'

/**
 * When toggling grounding for a property, we must change the models that are
 * selected and available. This composable helps manage the state of grounding,
 * and updates the selected model when grounding is toggled.
 */
export const useGroundingToggle = () => {
  const { captureAnalyticsEvent } = useAnalytics()
  const propertyStore = useProperty()
  const projectStore = useProject()
  const modelProvidersStore = useModelProviders()

  const groundingConfig = computed(() =>
    modelProvidersStore.groundingConfigs.get(propertyStore.editedTool),
  )

  const isGroundingSupported = computed(() => !!groundingConfig.value)
  const supportedInputTypes = computed(() => groundingConfig.value?.supportedInputTypes ?? [])
  const supportedOutputTypes = computed(() => groundingConfig.value?.supportedOutputTypes ?? [])
  const isGroundingEnabled = computed(() => propertyStore.editedIsGrounded)

  const setGroundingState = (enabled: boolean) => {
    if (isGroundingEnabled.value == enabled) return enabled

    if (enabled) {
      if (!areConditionsFilled.value) return false
      captureAnalyticsEvent(ANALYTICS_EVENT.GROUNDING_ENABLED)
    }

    propertyStore.editedIsGrounded = enabled
    return enabled
  }

  type Condition = { fulfilled: boolean; label: string }

  const orList = (list: string[]) => {
    if (list.length === 0) return ''
    if (list.length === 1) return list[0]
    return `${list.slice(0, -1).join(', ')} or ${list.at(-1)}`
  }

  const orPropertyTypes = (propertyTypes: PropertyType[]) => {
    const labels = propertyTypes.map((type) => FIELD_TYPES_LABELS[type])
    return orList(labels)
  }

  const groundingConditions: ComputedRef<Condition[]> = computed(() => {
    const conditions = [
      {
        fulfilled: isGroundingSupported.value,
        label: 'Tool supports AI Citations',
      },
      {
        fulfilled: propertyStore.editedInputs.some(
          ({ propertyId }) => projectStore.propertiesById[propertyId]?.type === PropertyType.file,
        ),
        label: 'At least one input is a file property',
      },
    ]
    if (isGroundingSupported.value) {
      conditions.push(
        {
          fulfilled: supportedOutputTypes.value.includes(propertyStore.editedType),
          label: `Property type is ${orPropertyTypes(supportedOutputTypes.value)}`,
        },
        {
          fulfilled: propertyStore.editedInputs.every(({ propertyId }) => {
            const property = projectStore.propertiesById[propertyId]
            return property && supportedInputTypes.value.includes(property.type)
          }),
          label: `All inputs' types are ${orPropertyTypes(supportedInputTypes.value)}`,
        },
      )
    }
    return conditions
  })

  const areConditionsFilled = computed(() =>
    groundingConditions.value.every(({ fulfilled }) => fulfilled),
  )

  watchEffect(() => {
    if (!areConditionsFilled.value && propertyStore.editedIsGrounded)
      propertyStore.editedIsGrounded = false
  })

  return {
    isGroundingEnabled,
    setGroundingState,
    groundingConditions,
    areConditionsFilled,
  }
}
