<script setup lang="ts">
import { autoUpdate, flip, offset, useFloating } from '@floating-ui/vue'
import { UseFocusTrap } from '@vueuse/integrations/useFocusTrap/component'
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'
import { Color } from '@/shared/Color'
import ColorSwatch from '@/uiKit/ColorSwatch.vue'

defineProps<{
  value?: string | null
}>()

defineEmits<{
  (e: 'change', value: string): void
}>()

const open = ref(false)
const target = ref<HTMLElement>()
const floatingMenu = ref<HTMLElement>()

const { floatingStyles: floatingMenuStyles } = useFloating(target, floatingMenu, {
  placement: 'top-start',
  middleware: [flip(), offset({ crossAxis: -6 })],
  whileElementsMounted: autoUpdate,
})

onClickOutside(floatingMenu, () => {
  open.value = false
})
</script>

<template>
  <div
    v-bind="$attrs"
    ref="target"
    tabindex="0"
    aria-label="Color Preset Picker"
    class="flex size-6 cursor-pointer items-center justify-center gap-2 rounded-md p-1.5 hover:bg-background-transparent-hovered focus-visible:bg-background-transparent-pressed focus-visible:outline-none"
    :class="open && 'bg-background-transparent-pressed'"
    @click="open = !open"
    @keydown.enter="open = true"
  >
    <ColorSwatch :value="value" />
  </div>

  <Teleport
    v-if="open"
    to="body"
  >
    <UseFocusTrap
      id="color-preset-menu"
      ref="floatingMenu"
      aria-label="Color Preset Menu"
      class="grid h-auto shrink grow-0 select-none grid-flow-row grid-cols-8 rounded-xl bg-surface-popover p-1 shadow-lg outline outline-1 outline-border-subtle"
      :style="floatingMenuStyles"
    >
      <div
        v-for="color in Color"
        :key="color"
        tabindex="0"
        role="button"
        class="flex size-7 cursor-pointer items-center justify-center rounded-lg hover:bg-background-gray-subtlest-hovered focus-visible:bg-background-gray-subtlest-pressed focus-visible:outline-none"
        :class="value === color && 'bg-background-gray-subtlest'"
        @click="$emit('change', color), (open = false)"
        @keydown.enter="$emit('change', color), (open = false)"
        @keydown.escape="open = false"
      >
        <div
          class="size-3.5 rounded border border-border-subtle"
          :style="{ background: `var(--${color}-light)` }"
        ></div>
      </div>
    </UseFocusTrap>
  </Teleport>
</template>
