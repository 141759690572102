<script setup lang="ts" generic="T extends RadioItem">
import * as radio from '@zag-js/radio-group'
import { normalizeProps, useMachine } from '@zag-js/vue'
import { computed, watch } from 'vue'

/**
 * Radio group. Renders a group of radio buttons, where the user can only
 * select one at a time.
 * https://www.figma.com/design/1HfA941cU4A9RZxXHLmbpG/V7-Go---Design-System?node-id=194-1657&node-type=frame&t=44GaIiQwUkQuLHHU-0
 */

export type RadioItem<Value extends string = string> = {
  label: string
  value: Value
}

const props = withDefaults(
  defineProps<{
    items: T[]
    value: T['value']
    /**
     * The name of the radio group.
     * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#defining_a_radio_group
     */
    name: string
    size?: 'xs' | 'sm' | 'md'
    transparent?: boolean
  }>(),
  {
    size: 'md',
    transparent: false,
  },
)

const emit = defineEmits<{
  (e: 'change', value: T['value']): void
}>()

const [state, send] = useMachine(
  radio.machine({
    // The ID is used to ensure that every part of this component has a unique identifier
    id: props.name,
    value: props.value,
    onValueChange: ({ value }) => {
      if (value === props.value) {
        return
      }

      emit('change', value)
    },
    orientation: 'horizontal',
    name: props.name,
  }),
)

watch(
  () => props.value,
  (newValue) => {
    api.value.setValue(newValue)
  },
)

const api = computed(() => radio.connect(state.value, send, normalizeProps))

const isChecked = ({ value }: T): boolean => {
  return api.value.getItemState({ value }).checked
}
</script>

<template>
  <div
    v-bind="api.getRootProps()"
    class="flex flex-col gap-2"
  >
    <label
      v-for="opt in items"
      :key="opt.value"
      v-bind="api.getItemProps({ value: opt.value })"
      class="flex items-center gap-2"
    >
      <div
        class="flex size-3.5 items-center justify-center rounded-corner-8 border-[1.5px] text-icon-inverted outline-border-focused transition has-[:focus-visible]:outline disabled:border-none disabled:bg-background-disabled disabled:text-icon-disabled"
        :class="[
          isChecked(opt)
            ? '!border-[3px] border-background-primary hover:border-background-primary-hovered active:border-background-primary-pressed [&_circle]:fill-icon-inverted'
            : 'border-border-input bg-background-transparent hover:bg-background-transparent-hovered active:bg-background-transparent-pressed',
          ,
        ]"
      >
        <input v-bind="api.getItemHiddenInputProps({ value: opt.value })" />
      </div>
      <span
        v-bind="api.getItemTextProps({ value: opt.value })"
        class="text-sm-12px-default"
        :class="isChecked(opt) ? 'text-text' : 'text-text-subtle'"
        >{{ opt.label }}</span
      >
    </label>
  </div>
</template>
