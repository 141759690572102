<script setup lang="ts">
import { computed, ref, toRef, watch } from 'vue'
import { useProject, type Field, type Property } from './useProject'
import CollectionCell from './CollectionCell.vue'
import { useFieldStatusIndicator } from './useFieldStatusIndicator'
import { resolveFilename } from './resolveFilename'
import FileCellEmpty from './FileCellEmpty.vue'
import {
  PropertyType,
  SUPPORTED_FILE_EXTENSIONS,
  SUPPORTED_FILE_EXTENSIONS_HELPER,
} from '@/backend/types'
import { toast } from '@/shared/toast'
import { useSupportedMimeTypes } from './useSupportedMimeTypes'
import { useFieldUpload } from './useFieldUpload'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { useLimitedAction } from '../Billing/useLimitedAction'
import ConfirmationDialog from '@/uiKit/ConfirmationDialog.vue'
import RadioGroup, { type RadioItem } from '@/uiKit/RadioGroup.vue'
import { useListEntities } from './useListEntities'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import FileCellPopulated from './FileCellPopulated.vue'
import { getFilesAndDirectoriesFromDragEvent, type Directory } from '@/shared/utils/file'
import FileUpload from '@/uiKit/FileUpload.vue'
/**
 * Renders a file collection field in the table. File collections should have
 * - the same UI as a file field when there are 0 items in the collection
 * - the same UI as a file field when there is 1 item in the collection, but allowing for
 *   additional files to be uploaded
 * - the same UI as a collection field when there are 2 or more items in the collection
 */

const props = defineProps<{
  field: Field<'file_collection'>
  property: Property<'file_collection'>
  colIndex: number
  viewId?: string
  workspaceId: string
  isFocused: boolean
  isSelected: boolean
  hasSelectedRange: boolean
}>()

defineEmits<{
  (e: 'saveName', value: string): void
  (e: 'blur'): void
}>()

const cellToRender = computed<'file' | 'collection'>(() => {
  if (!props.field.subprojectPreview) {
    return 'file'
  }

  if (props.field.subprojectPreview.entityCount > 1) {
    return 'collection'
  }

  return 'file'
})

const subprojectId = computed(() => props.property.config?.subprojectConfig.child_project_id)
const filePropertyId = computed(() => props.property.config?.subprojectConfig.primary_property_id)

const fieldValue = computed(() => {
  return props.field.manualValue || props.field.toolValue || ''
})

const entityCount = computed(() => props.field.subprojectPreview?.entityCount ?? 0)

const { isModelOutput } = useFieldStatusIndicator(toRef(props.field))

const filename = computed(() => resolveFilename(props.field))
const firstFileUrl = computed(() => {
  if (!props.field.subprojectPreview || !filePropertyId.value) {
    return null
  }

  const firstSubprojectEntity = props.field.subprojectPreview.entityPreviews[0]
  if (!firstSubprojectEntity) {
    return null
  }

  const fileField = firstSubprojectEntity.field_previews.find(
    (preview) => preview.property_id === filePropertyId.value,
  )
  if (!fileField) {
    return null
  }

  const url = fileField.manual_value.value
  if (Array.isArray(url)) {
    throw new Error('File field has multiple values')
  }

  return url
})

const supportedFileExtensions = [...SUPPORTED_FILE_EXTENSIONS]
const supportedMimeTypes = useSupportedMimeTypes()

const uploadStore = useFieldUpload()
const projectStore = useProject()
const { captureAnalyticsEvent } = useAnalytics()

/**
 * This function will upload a file to a subproject entity. If the subproject
 * has no entities, it will create one and upload it to the new entity.
 */
const uploadFileToSubProject = async ({
  parentEntityId,
  subprojectPreview,
  file,
}: {
  parentEntityId: string
  subprojectPreview?: Field<'file_collection'>['subprojectPreview']
  file: File
}) => {
  const projectId = props.property.config?.subprojectConfig.child_project_id
  const propertyId = props.property.config?.subprojectConfig.primary_property_id
  assertIsNotNullOrUndefined(projectId, 'file_collection property has no child project')
  assertIsNotNullOrUndefined(propertyId, 'file_collection property has no primary property')

  let entityId: string | undefined
  // Handle an edge case in this block. It's possible that the subproject has an empty
  // entity, if the user has deleted all files from the subproject. In this case we should
  // upload a file to that entity instead of creating a new one.
  if (subprojectPreview?.entityPreviews.length === 0 && subprojectPreview.entityCount > 0) {
    const res = await listEntities({
      workspaceId: props.workspaceId,
      projectId,
      start: 0,
      end: 0,
      parentEntityId,
    })

    if (res.ok && res.data.data.length > 0) {
      entityId = res.data.data[0].id
    }
  }

  if (!entityId) {
    const res = await addEntity({
      projectId,
      workspaceId: props.workspaceId,
      parentEntityId,
    })
    if (!res.ok) {
      throw new Error('Failed to create subproject entity')
    }

    entityId = res.data.id
  }

  uploadStore.addUpload({
    workspaceId: props.workspaceId,
    projectId,
    entityId,
    propertyId,
    file,
  })

  captureAnalyticsEvent(ANALYTICS_EVENT.FILE_UPLOAD_PENDING, {
    workspaceId: props.workspaceId,
    projectId,
    entityId,
    propertyId,
  })
}

const listEntities = useListEntities()
const dialogFiles = ref<File[]>([])
const dialogDirectories = ref<Directory[]>([])

const getAllNestedFilesFromDirectory = (directory: Directory): File[] =>
  directory.children.reduce<File[]>((acc, child) => {
    if (child instanceof File) {
      return [...acc, child]
    }

    return [...acc, ...getAllNestedFilesFromDirectory(child)]
  }, [])

/**
 * Upload all pending files to subproject entities.
 */
const uploadFiles = async (directories: Directory[]) => {
  dialogFiles.value = []
  dialogDirectories.value = []
  const allFiles = directories.flatMap(getAllNestedFilesFromDirectory)
  const unsupportedFiles = allFiles.filter((f) => {
    // Some files have no type, so we fallback to the extension
    // https://developer.mozilla.org/en-US/docs/Web/API/Blob/type
    return f.type === ''
      ? !(f.name.split('.').pop() ?? '' in SUPPORTED_FILE_EXTENSIONS_HELPER)
      : !supportedMimeTypes.value.includes(f.type)
  })

  if (unsupportedFiles.length > 0) {
    toast.warning(
      [
        'Some of the files you tried to upload are not supported, so the upload was skipped.',
        'We only support the following file extensions:',
        supportedFileExtensions.join(', ').concat('.'),
      ].join(' '),
    )
    return
  }

  const projectId = props.property.config?.subprojectConfig.child_project_id
  const propertyId = props.property.config?.subprojectConfig.primary_property_id
  assertIsNotNullOrUndefined(projectId, 'file_collection property has no child project')
  assertIsNotNullOrUndefined(propertyId, 'file_collection property has no primary property')

  if (uploadMode.value === 'one_entity') {
    /**
     * It's simple to upload all files to a single entity because we have all
     * the information we need from props.
     */
    await Promise.all(
      allFiles.map(async (file) =>
        uploadFileToSubProject({
          parentEntityId: props.field.entityId,
          subprojectPreview: props.field.subprojectPreview,
          file,
        }),
      ),
    )
    return
  } else {
    /**
     * It's more complicated to upload 1 file to each entity. We still upload
     * the files to subproject entities, but the parent entity will be different
     * for each file.
     *
     * There's also the added complexity that we might need to create the parent
     * entity if dragging a large number of files to an entity at the bottom
     * of the table.
     */
    const fileQueue: File[][] = directories.map(getAllNestedFilesFromDirectory)
    const currentIndex = projectStore.activeView?.entityIdToIndex?.get(props.field.entityId) ?? -1
    const remainingEntities = projectStore.activeView?.entities?.slice(currentIndex) ?? []

    // First upload files to existing entities
    await Promise.all([
      ...remainingEntities.map(async (entity) => {
        const files = fileQueue.shift()
        if (!entity || !files) {
          return
        }

        const fileCollectionField = entity.fields.get(props.field.propertyId)
        assertIsNotNullOrUndefined(fileCollectionField, 'file collection field not found')
        if (fileCollectionField.type !== PropertyType.file_collection) {
          return
        }

        await Promise.all(
          files.map(async (file) =>
            uploadFileToSubProject({
              parentEntityId: entity.id,
              subprojectPreview: fileCollectionField.subprojectPreview,
              file,
            }),
          ),
        )
      }),
    ])

    // Now create new entities for the remaining files
    await Promise.all(
      fileQueue.map(async (files) => {
        assertIsNotNullOrUndefined(projectStore.projectId, 'no project id')
        // First create the parent entity
        const parentEntityRes = await addEntity({
          projectId: projectStore.projectId,
          workspaceId: props.workspaceId,
        })
        if (!parentEntityRes.ok) {
          return
        }

        await Promise.all(
          files.map((file) =>
            uploadFileToSubProject({ parentEntityId: parentEntityRes.data.id, file }),
          ),
        )
      }),
    )
  }
}
const { addEntity } = useLimitedAction()

type UploadMode = 'one_entity' | 'multiple_entities'
const uploadMode = ref<UploadMode>('one_entity')
const uploadOptions = computed<Array<RadioItem<UploadMode>>>(() => {
  const isUploadingDirectories = dialogDirectories.value.length > 0

  return [
    {
      label: isUploadingDirectories
        ? 'Import all folders in one collection'
        : 'Import all files as one single collection property',
      value: 'one_entity',
    },
    {
      label: isUploadingDirectories
        ? 'Import each folder as a collection'
        : 'Import each file as an entity',
      value: 'multiple_entities',
    },
  ]
})

const onUploadFiles = (files: File[]) => {
  if (files.length < 2) {
    uploadFiles([{ name: '', children: files }])
    return
  }

  dialogFiles.value = files
}

const onUploadDirectories = (directories: Directory[]) => {
  if (directories.length === 0) {
    return
  }

  if (directories.length === 1) {
    if (directories[0].children.every((c) => c instanceof File)) {
      dialogFiles.value = directories[0].children
      return
    }
  }

  dialogDirectories.value = directories
}

const confirmationDialog = computed<{
  title: string
  description: string
  isOpen: boolean
  onConfirm: () => void
}>(() => {
  if (dialogFiles.value.length === 0 && dialogDirectories.value.length === 0) {
    return {
      title: '',
      description: '',
      isOpen: false,
      onConfirm: () => {},
    }
  }

  if (dialogDirectories.value.length > 0) {
    return {
      title: 'Import data to your collection',
      description: `You imported ${dialogDirectories.value.length} folders. What do you want to do?`,
      isOpen: true,
      onConfirm: () => uploadFiles(dialogDirectories.value),
    }
  }

  return {
    title: 'Import several files',
    description: `You imported ${dialogFiles.value.length} files. What do you want to do?`,
    isOpen: true,
    onConfirm: () => uploadFiles(dialogFiles.value.map((f) => ({ name: '', children: [f] }))),
  }
})

// Reset the upload mode whenever files are added so that the default selection is always
// "Import all files as one single collection property"
watch(dialogFiles, () => {
  uploadMode.value = 'one_entity'
})

const onCancelImport = () => {
  dialogFiles.value = []
  dialogDirectories.value = []
}

/**
 * Handle a drop event on a populated FileCollection cell. Upload all files in the dropped
 * directories to the same file collection.
 */
const onDrop = async (e: DragEvent) => {
  const directoriesAndFiles = await getFilesAndDirectoriesFromDragEvent(e)
  if (!directoriesAndFiles || directoriesAndFiles.length === 0) {
    return
  }

  const asDirectories = directoriesAndFiles.map<Directory>((item) => {
    if (item instanceof File) {
      return { name: '', children: [item] }
    }

    return item
  })

  uploadMode.value = 'one_entity'
  uploadFiles(asDirectories)
}

defineOptions({ inheritAttrs: false })
</script>

<template>
  <FileCellEmpty
    v-if="cellToRender === 'file' && subprojectId && filePropertyId && entityCount === 0"
    v-bind="$attrs"
    :field="field"
    :property="property"
    :property-id="filePropertyId"
    :project-id="subprojectId"
    :has-selected-range="hasSelectedRange"
    :workspace-id="workspaceId"
    :is-focused="isFocused"
    :url="null"
    :pdf-project-id="subprojectId"
    :is-selected="isSelected"
    :filename="filename"
    @upload:files="onUploadFiles"
    @upload:directories="onUploadDirectories"
  />
  <FileUpload
    v-else
    v-slot="{ isDragging, dropzoneProps }"
  >
    <FileCellPopulated
      v-if="
        cellToRender === 'file' &&
        filename &&
        firstFileUrl &&
        subprojectId &&
        filePropertyId &&
        entityCount === 1
      "
      v-bind="{ ...dropzoneProps, ...$attrs }"
      :field="field"
      :property="property"
      :property-id="filePropertyId"
      :project-id="subprojectId"
      :has-selected-range="hasSelectedRange"
      :workspace-id="workspaceId"
      :is-focused="isFocused"
      :url="firstFileUrl"
      :pdf-project-id="subprojectId"
      :is-selected="isSelected"
      :filename="filename"
      :class="
        isDragging &&
        'rounded-corner-4 bg-background-selected outline outline-1 outline-border-focused'
      "
      @blur="$emit('blur')"
      @drop="onDrop"
    />
    <CollectionCell
      v-else-if="cellToRender === 'collection' && subprojectId && entityCount > 1"
      v-bind="{ ...dropzoneProps, ...$attrs }"
      :title="fieldValue"
      :is-model-output="isModelOutput"
      :is-focused="isFocused"
      :is-selected="isSelected"
      :value="String(entityCount)"
      :view-id="viewId"
      :tool="property.tool"
      :col-index="colIndex"
      :collection-project-id="subprojectId"
      :url="fieldValue"
      :workspace-id="workspaceId"
      :project-id="subprojectId"
      :entity-id="field.entityId"
      :name="field?.manualName ? field?.manualName : undefined"
      :class="
        isDragging &&
        'rounded-corner-4 bg-background-selected outline outline-1 outline-border-focused'
      "
      @save-name="$emit('saveName', $event)"
      @drop="onDrop"
    />
  </FileUpload>

  <ConfirmationDialog
    class="min-w-[480px]"
    :open="confirmationDialog.isOpen"
    :title="confirmationDialog.title"
    confirm-text="Save"
    :description="confirmationDialog.description"
    variant="black"
    @close="onCancelImport"
    @confirm="confirmationDialog.onConfirm"
  >
    <RadioGroup
      :items="uploadOptions"
      :value="uploadMode"
      name="Upload mode"
      @change="uploadMode = $event"
    />
  </ConfirmationDialog>
</template>
