import type { components } from '@/api'
import type { Property } from '../useProject'

/** Groups a list of filters together with a conjunction. */
export type GroupFilter = components['schemas']['EntityFilters.GroupFilter']

/** A single filter */
export type SimpleFilter = components['schemas']['EntityFilters.SimpleFilter']

/**
 * A recursive data structure, since a GroupFilter contains a list of filters,
 * which in turn can be GroupFilters themselves.
 */
export type Filter = GroupFilter | SimpleFilter

export type SelectOptionValueFilter = components['schemas']['FieldSelectOptionValue']

export type TextValueFilter = components['schemas']['FieldTextValue']

export const isSimpleFilter = (filter: Filter): filter is SimpleFilter => !('conjunction' in filter)

export type FilterableProperty = Property<'text' | 'single_select' | 'multi_select' | 'user_select'>

/**
 * Returns true if and only if a property can have filters applied to it.
 */
export const isFilterableProperty = (property: Property): property is FilterableProperty =>
  property.type === 'text' ||
  property.type === 'single_select' ||
  property.type === 'multi_select' ||
  property.type === 'user_select'

/**
 * 'property_not_set' is a FE-only value that we convert to is_none_of <all_options>
 * when sending to the backend.
 */
export type MatcherName = SimpleFilter['matcher']['name'] | 'property_not_set'

export type TextFilterMatcher = TextValueFilter['matcher']['name']

/**
 * Returns true if and only if the given matcher is a valid matcher name for
 * a text filter.
 */
export const isValidTextMatcher = (
  matcher: MatcherName,
): matcher is TextValueFilter['matcher']['name'] => {
  // Use a record so that TS will error if a new matcher is added to the API
  // and not added here.
  const validMatchers: Record<TextFilterMatcher, true> = {
    property_any_of: true,
    property_contains_any_of: true,
    property_contains_none_of: true,
    property_none_of: true,
  }

  return matcher in validMatchers
}

/**
 * Will throw an error if the given matcher is not a valid text filter matcher.
 */
export function assertIsValidTextMatcher(
  matcher: MatcherName,
): asserts matcher is TextFilterMatcher {
  if (!isValidTextMatcher(matcher)) {
    throw new Error('The provided matcher is not a valid text filter matcher')
  }
}

export type SelectFilterMatcher = SelectOptionValueFilter['matcher']['name']

/**
 * Returns true if and only if the given matcher is a valid matcher name for
 * a select filter.
 */
export const isValidSelectMatcher = (matcher: MatcherName): matcher is SelectFilterMatcher => {
  const validMatchers: Record<SelectFilterMatcher, true> = {
    property_all_of: true,
    property_any_of: true,
    property_none_of: true,
  }

  return matcher in validMatchers
}

/**
 * Will throw an error if the given matcher is not a valid select filter matcher.
 */
export function assertIsValidSelectMatcher(
  matcher: MatcherName,
): asserts matcher is SelectFilterMatcher {
  if (!isValidSelectMatcher(matcher)) {
    throw new Error('The provided matcher is not a valid select filter matcher')
  }
}
