<script setup lang="ts">
import { computed } from 'vue'

import type { LayoutItem } from './EntityViewCard.vue'
import CardTextEditor from './CardTextEditor.vue'
import ProseMirror from '@/sharedComponents/ProseMirror/ProseMirror.vue'
import { FeatureFlag, useFeatureFlags } from '../App/useFeatureFlags'

const props = defineProps<{
  item: LayoutItem
  json: boolean
  readonly: boolean
}>()

const emit = defineEmits<{
  (e: 'submit', val: string): void
}>()

const value = computed(() =>
  props.item.field.type === 'text' ||
  props.item.field.type === 'json' ||
  props.item.field.type === 'url'
    ? props.item.field.manualValue || props.item.field.toolValue
    : '',
)

const status = computed(() => props.item.field.status)

const infoText = computed<string | undefined>(() => {
  if (status.value === 'error') {
    return `Error: ${props.item.field.errorMessage}`
  }

  if (status.value === 'computing') {
    return 'Calculating...'
  }

  return undefined
})

const onSave = (_: Event, text: string) => {
  emit('submit', text)
}

const isMarkDownFlagEnabled = useFeatureFlags(FeatureFlag.MARKDOWN_EDITOR)

const shouldUseMarkdownEditor = computed(() => {
  return isMarkDownFlagEnabled.value && props.item.field.type === 'text'
})
</script>

<template>
  <ProseMirror
    v-if="shouldUseMarkdownEditor"
    class="size-full rounded-corner-6"
    :value="value ?? ''"
    :readonly="item.readonly || readonly"
    @save="onSave"
  />
  <CardTextEditor
    v-else
    :info-text="infoText"
    :value="value ?? ''"
    :json="json"
    :readonly="item.readonly || readonly"
    :label="item.property?.name"
    @submit="$emit('submit', $event)"
  />
</template>
