<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import PopOver from '@/uiKit/PopOver.vue'
import { usePreviewChanges } from './usePreviewChanges'
import { onKeyStroke } from '@vueuse/core'
/**
 * Renders a Popover that allows the user to save or cancel changes made to
 * the preview range of cells.
 */

const { resetPreview, savePreviewChanges } = usePreviewChanges()

onKeyStroke('Escape', (e) => {
  e.stopPropagation()
  resetPreview()
})

onKeyStroke('Enter', (e) => {
  e.stopPropagation()
  savePreviewChanges()
})

const dialogLabelId = 'preview-changes-dialog-label'
</script>

<template>
  <PopOver
    :open="true"
    :placement="{ allowedPlacements: ['top-start'] }"
    :offset="{ mainAxis: 24, alignmentAxis: -2 }"
    role="dialog"
    :aria-labelledby="dialogLabelId"
  >
    <template #content>
      <div
        class="flex h-9 items-center rounded-xl bg-surface-tertiary"
        data-theme="dark"
      >
        <div
          :id="dialogLabelId"
          class="pl-4 pr-3 text-sm-12px-default text-text"
        >
          Previewing paste
        </div>
        <div class="flex h-full items-center gap-1 rounded-xl bg-surface-primary px-1">
          <DarwinButton
            variant="transparent"
            size="sm"
            @click="savePreviewChanges"
            >Save</DarwinButton
          >
          <DarwinButton
            variant="critical-transparent"
            size="sm"
            @click="resetPreview"
            >Cancel</DarwinButton
          >
        </div>
      </div>
    </template>
  </PopOver>
</template>
