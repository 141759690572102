<script setup lang="ts">
import { EditorState, Selection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { ref, shallowRef, watch } from 'vue'
import { defaultMarkdownParser, defaultMarkdownSerializer } from 'prosemirror-markdown'
import { exampleSetup } from 'prosemirror-example-setup'
import { markdownSchema } from './markdown'
import { useMarkState } from './useMarkState'
const props = defineProps<{
  value: string
  readonly?: boolean
  adaptForTableMode?: boolean
}>()

const emit = defineEmits<{
  (e: 'save', event: Event, value: string): void
  (e: 'cancel'): void
  (e: 'enter', event: KeyboardEvent): void
}>()

const state = shallowRef<EditorState>()
const view = shallowRef<EditorView>()

const editor = ref<HTMLDivElement>()

const { setMarkState } = useMarkState(view)

// tracks if the user has edited the content, since the blur event is triggered always and serialization always returns some formatting differences
const hasValueChanged = ref(false)
const shouldSkipBlur = ref(false)

watch(
  () => [props.value, props.readonly],
  () => {
    hasValueChanged.value = false
    // updates the editor value, god this looks ugly, isn't there a better way?
    if (view.value) {
      const newState = EditorState.create({
        plugins: [...exampleSetup({ schema: markdownSchema, floatingMenu: false, menuBar: false })],
        doc: defaultMarkdownParser.parse(props.value),
      })
      view.value.updateState(newState)
      setMarkState(newState)
    }
  },
)

defineExpose({
  focus() {
    view.value?.focus()
    // puts the caret at the end of the text
    if (!view.value) return
    const selection = Selection.atEnd(view.value.state.doc)
    const tr = view.value.state.tr.setSelection(selection)
    const state = view.value.state.apply(tr)
    view.value.updateState(state)
    // scrolls so that the caret is visible
    view.value.dispatch(view.value.state.tr.scrollIntoView())
  },
})

// Initialise the rich text editor as soon as the editor element is available
watch(
  () => editor.value,
  () => {
    if (!editor.value) return
    state.value = EditorState.create({
      plugins: [...exampleSetup({ schema: markdownSchema, floatingMenu: false, menuBar: false })],
      doc: defaultMarkdownParser.parse(props.value),
    })

    view.value = new EditorView(editor.value, {
      state: state.value,
      editable: () => !props.readonly,
      dispatchTransaction(transaction) {
        if (!view.value) {
          return
        }
        if (transaction.docChanged) {
          hasValueChanged.value = true
        }

        let newState = view.value.state.apply(transaction)

        view.value.updateState(newState)
        setMarkState(newState)
      },
      handleDOMEvents: {
        focus() {
          view.value?.focus()
        },
        keydown(_e, event: KeyboardEvent) {
          if (
            !props.adaptForTableMode &&
            (event.key === 'ArrowLeft' ||
              event.key === 'ArrowRight' ||
              event.key === 'ArrowUp' ||
              event.key === 'ArrowDown')
          ) {
            // stop propagation in entity view mode and avoid navigation between previous and next entities
            event.stopPropagation()
          }

          if (!props.adaptForTableMode) return
          event.stopPropagation()

          if (event.key === 'Escape') {
            event.preventDefault()
            emit('cancel')
            shouldSkipBlur.value = true
          }

          if (event.key === 'Enter') {
            if (event.shiftKey) {
              return
            }
            event.preventDefault()
            emit('enter', event)
          }
        },
        blur(_view: EditorView, event: Event) {
          if (shouldSkipBlur.value) {
            shouldSkipBlur.value = false
            return
          }
          const state = view.value?.state
          if (!state) return
          if (!hasValueChanged.value) return

          const serialized = defaultMarkdownSerializer.serialize(state.doc)

          emit('save', event, serialized)
        },
      },
    })
  },
  { immediate: true },
)
</script>

<template>
  <div class="flex flex-col">
    <div
      ref="editor"
      class="size-full min-w-full cursor-text overflow-y-auto [&>*:focus-visible]:outline-none [&>*]:size-full [&>*]:px-3 [&>*]:py-1.5"
      :class="adaptForTableMode && 'w-max max-w-[500px]'"
    />
  </div>
</template>
